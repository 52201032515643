import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { WebhooksFacade } from '@webhooks/shared/webhooks.facade';
import { Webhook, WebhooksFilters, WebhooksListTableColumns } from '@webhooks/shared/webhooks.model';
import { AutoUnsubscribe, UniAuthFacade, UniTableSelection } from 'unifonic-common/src';
import { values } from 'lodash';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'fs-webhook-table',
  templateUrl: './webhook-table.component.html',
  styleUrls: ['./webhook-table.component.scss']
})
export class WebhookTableComponent extends UniTableSelection<Webhook> implements OnInit {
  @ViewChild('table', { static: false }) table: ElementRef;
  dataSource$: Observable<MatTableDataSource<Webhook>>;
  isModalActive = false;
  columns = WebhooksListTableColumns;
  totalItems$ = this.webhooksFacade.webhooksAmount$;
  deleteWebhookId: string;
  filters = WebhooksFilters;
  tableLeft: number;

  get displayedColumns(): string[] {
    return values(this.columns);
  }

  constructor(
    private webhooksFacade: WebhooksFacade
  ) {
    super();
  }

  ngOnInit(): void {
    this.setDataSource();
    setTimeout(() => this.tableLeft = this.getTableOffsetLeft(), 0);
  }

  getTableOffsetLeft(): number {
    return this.table.nativeElement.getBoundingClientRect().left;
  }

  setDataSource(): void {
    this.dataSource$ = this.webhooksFacade.webhooks$
      .pipe(
        filter((webhook: Webhook[]) => !!webhook),
        map((webhook: Webhook[]) => new MatTableDataSource<Webhook>(webhook)),
        tap((dataSource: MatTableDataSource<Webhook>) => this.dataSource = dataSource),
      );
  }

  onRemove(): void {
    this.webhooksFacade.deleteWebhook(this.deleteWebhookId);
  }

  openConfirmModal(id: string): void {
    this.deleteWebhookId = id;
    this.isModalActive = true;
  }

  toggleModal(): void {
    this.isModalActive = !this.isModalActive;
  }

}
