import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UniDataState } from '../store/uni-data.state';
import * as UniDataActions from '../store/uni-data.actions';
import { selectActivePackages } from '../store/uni-data.selectors';

@Injectable({ providedIn: 'root' })
export class PackagesFacade {
  packages$ = this.store.pipe(select(selectActivePackages));

  constructor(private store: Store<UniDataState>) {}

  setActivePackages(): void {
    this.store.dispatch(new UniDataActions.SetActivePackages());
  }
}
