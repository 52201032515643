<uni-container>
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-text-body>
         {{ 'table.webhooks' | translate }}
        </uni-text-body>
      </uni-title-item>
      <uni-title-item>
        <uni-button (click)="onAddNew()">
          {{ 'label.newWebhook' | translate }}
          <uni-icon-action></uni-icon-action>
        </uni-button>
      </uni-title-item>
    </uni-title-container>
    <uni-separator></uni-separator>
  </uni-section>
  <uni-section>
    <fs-webhook-table></fs-webhook-table>
  </uni-section>
</uni-container>

<uni-loader *ngIf="isLoaderActive$ | async"></uni-loader>
