import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLoggedComponent } from './layout/containers/app-logged/app-logged.component';
import { AuthGuard } from '@auth/shared/auth.guard';
import { AuthGuard as UniAuthGuard, UniPage403Component, UniPage404Component } from '@unifonic/common';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'webhook/list',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AppLoggedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'webhook',
        canActivate: [UniAuthGuard],
        loadChildren: () => import('./+webhooks/webhooks.module').then(mod => mod.WebhooksModule)
      }
    ]
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: '403',
    component: UniPage403Component,
  },
  {
    path: '404',
    component: UniPage404Component,
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
