import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoUnsubscribe, HttpQueryFacade } from '@unifonic/common';
import { WEBHOOKS_KEY } from '@webhooks/store';
import { WebhooksFacade } from '@webhooks/shared/webhooks.facade';

@Component({
  selector: 'fs-webhook-list-rules',
  templateUrl: './webhook-list.component.html',
})
export class WebhookListComponent extends AutoUnsubscribe implements OnInit {
  isLoaderActive$ = this.httpQueryFacade.isInProgress$(WEBHOOKS_KEY);
  queryParams = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private webhooksFacade: WebhooksFacade,
    private httpQueryFacade: HttpQueryFacade,
    private router: Router
  ) {
    super();
  }
  ngOnInit(): void {
    this.fetchWebhooks();
  }

  onAddNew(): void {
    this.router.navigate(['/webhook/new']);
  }

  fetchWebhooks(): void {
    this.subs.sink = this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = params;
      this.webhooksFacade.getWebhooks(this.queryParams);
    });
  }
}
