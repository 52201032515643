import { Pipe, PipeTransform } from '@angular/core';
import { WebhookEvent, WebhookProductEvent } from '@webhooks/shared/webhooks.model';

@Pipe({
  name: 'webhooksEvents',
  pure: false
})
export class WebhooksEventsPipe implements PipeTransform {
  transform(items: WebhookEvent[], productEvent: WebhookProductEvent, productEvents: WebhookProductEvent[]): any {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.product.productId === productEvent.productId
      && (productEvent.eventId === item.eventId ||
        !productEvents.some(x => x.eventId === item.eventId && x.identifierValue === productEvent.identifierValue)));
  }
}
