/**
 * Campaigns are supported by two Unifonic projects: Communication Cloud (SMS and voice campaigns) and Conversations (WhatsApp).
 * For this reason, navigation between those two applications is required on some occasions.
 * These utils encapsulate the logic related to CC <-> Conv navigation and should be used when such navigation is required.
 */

const ccDomainPrefix = 'communication';
const ccCampaignsRoute = 'campaigns';
const campaignsDomainPrefix = 'campaign';
const campaignsRoute = 'management';

export const navigate = (URL: string): void => {
    window.open(URL, '_self');
};

const getDomainUrl = (currentPrefix: string, replacementPrefix: string): string => {
    return window.location.origin.replace(currentPrefix, replacementPrefix);
};

const getNormalizedRoute = (route: string): string => {
    if (route.charAt(0) === '/') {
        route = route.slice(1);
    }
    return route;
};

export const navigateFromCcToCampaigns = (route: string = null): void => {
    const URL = getDomainUrl(ccDomainPrefix, campaignsDomainPrefix) + (route ? `/${campaignsRoute}/${route}` : '');
    navigate(URL);
};

export const navigateFromCampaignsToCc = (route?: string): void => {
    const domainUrl = getDomainUrl(campaignsDomainPrefix, ccDomainPrefix);
    const URL = `${domainUrl}/${ccCampaignsRoute}/${route ? getNormalizedRoute(route) : ''}`;

    navigate(URL);
};

export const getURLFromCcToCampaigns = (): string => {
    return getDomainUrl(ccDomainPrefix, campaignsDomainPrefix);
};
