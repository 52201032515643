import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '@store/index';
import { selectEvents, selectProducts, selectQueuedEvents, selectWebhook, selectWebhooks, selectWebhooksAmount, WEBHOOKS_KEY, webhooksActions } from '../store';
import { Webhook, WebhooksParams } from './webhooks.model';
import { HttpQueryFacade } from '@unifonic/common';

@Injectable()
export class WebhooksFacade {
  webhooks$ = this.store.pipe(select(selectWebhooks));
  webhooksAmount$ = this.store.pipe(select(selectWebhooksAmount));
  webhook$ = this.store.pipe(select(selectWebhook));
  products$ = this.store.pipe(select(selectProducts));
  events$ = this.store.pipe(select(selectEvents));
  queuedEvents$ = this.store.pipe(select(selectQueuedEvents));
  isLoaderActive$ = this.httpQueryFacade.isInProgress$(WEBHOOKS_KEY);

  constructor(
    private store: Store<State>,
    private httpQueryFacade: HttpQueryFacade
  ) {}

  getWebhooks(params: WebhooksParams): void {
    this.store.dispatch(new webhooksActions.GetWebhooks(params));
  }

  getWebhook(webhookId: string): void {
    this.store.dispatch(new webhooksActions.GetWebhook(webhookId));
  }

  getProducts(): void {
    this.store.dispatch(new webhooksActions.GetProducts());
  }

  getEvents(): void {
    this.store.dispatch(new webhooksActions.GetEvents());
  }

  getQueuedEvents(webhookId: string): void {
    this.store.dispatch(new webhooksActions.GetQueuedEvents(webhookId));
  }

  postWebhook(accountId: string, payload: Webhook): void {
    this.store.dispatch(new webhooksActions.PostWebhook(accountId, payload));
  }

  putWebhook(accountId: string, payload: Webhook, webhookId: string): void {
    this.store.dispatch(new webhooksActions.PutWebhook(accountId, payload, webhookId));
  }

  deleteWebhook(webhookId: string): void {
    this.store.dispatch(new webhooksActions.DeleteWebhook(webhookId));
  }

  testWebhook(accountId: string, eventId: string, identifierValue?: string): void {
    this.store.dispatch(new webhooksActions.TestWebhook(accountId, eventId, identifierValue));
  }
}
