import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebhookFormComponent } from './containers/webhook-form/webhook-form.component';
import { UniCampaignModule, UniSharedModule } from '@unifonic/common';
import { WebhooksEventsPipe } from './shared/webhooks-events.pipe';
import { WebhookTestModalComponent } from './components/webhook-test-modal/webhook-test-modal.component';
import { WebhookSecretModalComponent } from './components/webhook-secret-model/webhook-secret-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { KeyValueVariableComponent } from './shared/key-value-variable/key-value-variable.component';
import { WebhooksListModule } from '@webhooks/modules/webhooks-list/webhooks-list.module';
import { MatCardModule } from '@angular/material/card';
import { PayloadComponent } from './components/payload/payload.component';


@NgModule({
  declarations: [
    WebhookTestModalComponent,
    WebhookSecretModalComponent,
    WebhookFormComponent,
    WebhooksEventsPipe,
    KeyValueVariableComponent,
    PayloadComponent
  ],
    imports: [
        CommonModule,
        UniSharedModule,
        ClipboardModule,
        WebhooksListModule,
        MatCardModule,
        UniCampaignModule
    ]
})
export class WebhookFormModule {
}
