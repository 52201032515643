<form
  (submit)="onSubmit()"
  [formGroup]="form"
  class="webhook-form">
  <uni-section [isFirst]="true">
    <uni-title-container>
      <uni-title-item>
        <uni-icon [isRightSpace]="true" name="angle-left" routerLink="/webhook/list"></uni-icon>
        <uni-text-body>
          {{ 'webhook.form.title' | translate }}
        </uni-text-body>
      </uni-title-item>
    </uni-title-container>
    <uni-separator></uni-separator>
  </uni-section>
  <uni-box>
    <uni-box-content>
      <div class="row">
        <div class="col-md-6">
          <div>
            <uni-form-field>
              <input [label]="'label.name' | translate" [placeholder]="'label.addName' | translate" [required]="true"
                     formControlName="name" type="text" uniInput>
            </uni-form-field>
            <uni-form-field [isButton]="true" [margin]="true">
              <input
                [placeholder]="'label.endpointUrl' | translate"
                formControlName="endpointUrl"
                type="text"
                uniInput
              >
              <uni-button
                (click)="addUrl()"
                [disabled]="isAddButtonDisabled"
                [isInputRelated]="true"
                [style]="'primary'"
              >
                {{ 'label.add' | translate }}
              </uni-button>
            </uni-form-field>
            <uni-form-field>
              <uni-multiselect-list *ngIf="webhookEndpointUrlsControl?.value?.length"
                                    [formControl]="webhookEndpointUrlsControl"
                                    [isTotalItems]="false"
                                    [noMargin]="true"
                                    [numbers]="true"
              ></uni-multiselect-list>
            </uni-form-field>
            <uni-form-field>
            <textarea [label]="'label.description' | translate" [placeholder]="'label.description' | translate"
                      formControlName="description"
                      type="text" uniInput></textarea>
            </uni-form-field>
            <uni-form-field class="application-new-form__switcher">
              <uni-label class="mb-0" [required]="true">
                {{ 'label.webhookStatus' | translate }}
              </uni-label>
              <uni-text
                class="mb-3"
                [weight]="uniTextWeight.regular"
                [color]="uniTextColor.darkLight"
                [isUppercase]="false">
                {{ 'webhook.status.labelDescription' | translate}}
              </uni-text>
              <uni-switcher formControlName="status">
                <uni-switcher-item [isActive]="true" [value]="true">
                  {{ 'webhook.status.active' | translate }}
                </uni-switcher-item>
                <uni-switcher-item [isActive]="false" [value]="false">
                  {{ 'webhook.status.inactive' | translate }}
                </uni-switcher-item>
              </uni-switcher>
              <uni-text
                class="mt-1"
                *ngIf="status && webhookId"
                [weight]="uniTextWeight.regular"
                [color]="uniTextColor.darkLight"
                [isUppercase]="false">
                {{ 'webhook.status.queuedEvents' | translate:{ queuedEvents: queuedEvents?.queuedEvents } }}
              </uni-text>
              <uni-text
                *ngIf="status && webhookId"
                [weight]="uniTextWeight.regular"
                [color]="uniTextColor.darkLight"
                [isUppercase]="false">
                {{ 'webhook.status.deliveryTime' | translate:{ deliveryTime: queuedEvents?.estimatedDeliveryTime || '0m' } }}
              </uni-text>
            </uni-form-field>
          </div>
        </div>
      </div>

      <uni-separator></uni-separator>

      <ng-container formArrayName="productEvents">
        <div class="d-flex justify-content-between">
          <uni-text-body [color]="uniTextColor.darkLight">{{'webhook.events' | translate}}</uni-text-body>
          <uni-button (click)="addEvent()" [fitContent]="true" [isMargin]="false" type="button">
            {{ 'webhook.addEvent' | translate }}
          </uni-button>
        </div>
        <div *ngFor="let eventControl of productEvents?.controls; index as i" class="webhook-form__event">
          <div class="d-flex justify-content-between align-items-center">
            <uni-text-body [color]="uniTextColor.darkLight">{{'webhook.event' | translate}} {{i + 1}}</uni-text-body>
            <div class="d-flex align-items-center">
              <uni-icon (click)="testWebhook(eventControl.value.eventId,eventControl.value.identifierValue)"
                        *ngIf="webhookId && showEvent[i]" name="play">
              </uni-icon>
              <uni-icon (click)="removeEvent(i)" *ngIf="productEvents?.controls.length > 1" [isAction]="true"
                        name="trash"></uni-icon>
              <uni-icon-action (click)="toggleEvent(i)" [isActive]="showEvent[i]" [isDropdown]="true"></uni-icon-action>
            </div>
          </div>

          <div *ngIf="showEvent[i]" [formGroupName]="i" class="mt-3">
            <div class="row">
              <div class="col-md-6">
                <div>
                  <uni-form-field>
                    <uni-label [required]="true">
                      {{ 'label.product' | translate }}
                    </uni-label>
                    <mat-form-field>
                      <mat-select [disableOptionCentering]="true" [placeholder]="'label.product' | translate"
                                  formControlName="productId">
                        <mat-option (onSelectionChange)="productChange(product, $event)"
                          *ngFor="let product of products" [value]="product.productId">
                          {{product.productName | uppercase}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </uni-form-field>

                  <uni-form-field *ngIf="showIdentifiers">
                    <uni-label [required]="true">
                      {{ 'label.applications' | translate }}
                    </uni-label>
                    <mat-form-field>
                      <mat-select [disableOptionCentering]="true" [placeholder]="'label.applications' | translate"
                                  formControlName="identifierValue" >
                        <mat-option (onSelectionChange)="updateIdentifier('WBA', i)"
                                    *ngFor="let application of whatsappApplications" [value]="application.applicationId">
                          {{application.applicationName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </uni-form-field>

                  <uni-form-field>
                    <uni-label [required]="true">
                      {{ 'label.eventType' | translate }}
                    </uni-label>
                    <mat-form-field>
                      <mat-select [disableOptionCentering]="true" [placeholder]="'label.eventType' | translate"
                                  formControlName="eventId">
                        <mat-option
                          (onSelectionChange)="eventChange(event)"
                          *ngFor="let event of eventsList | async | webhooksEvents: eventControl.value:productEvents.value"
                          [value]="event.eventId"
                        >
                          {{ translationsKey(event.eventName) | translate }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </uni-form-field>
                </div>
              </div>
            </div>
            <ng-container *ngIf="eventControl.value.eventId">
              <div class="row">
                <div class="col-md-6">
                  <div class="mt-4">
                    <uni-form-field>
                      <div class="d-flex justify-content-between">
                        <div>
                          <uni-label class="mb-0">
                            {{ 'label.deliveryThreshold' | translate }}
                          </uni-label>
                          <uni-text [weight]="uniTextWeight.regular" [color]="uniTextColor.darkLight" class="mt-0 pt-0">
                            {{ 'label.deliveryThresholdDescription' | translate }}
                          </uni-text>
                        </div>
                        <div>
                          <uni-toggle formControlName="batching" (click)="batchingClicked(i)"></uni-toggle>
                        </div>
                      </div>
                    </uni-form-field>
                  </div>
                  <div class="mt-4 ml-4" *ngIf="batchingControl(i).value && batchingConfigControl(i)">
                    <uni-form-container formGroupName="batchingConfig">
                      <uni-form-field>
                        <uni-label [required]="true">
                          {{ 'label.eventsPerMinute' | translate }}
                        </uni-label>
                        <mat-form-field>
                          <mat-select [disableOptionCentering]="true" [placeholder]="'label.eventsPerMinutePlaceholder' | translate "
                                      formControlName="batchLimit">
                            <mat-option
                              *ngFor="let limit of batchLimits" [value]="limit">
                              {{ kFormatter(limit) }} {{ 'label.thresholdOptionSuffix' | translate }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </uni-form-field>
                      <uni-form-field>
                        <uni-label [required]="true">
                          {{ 'label.batchSize' | translate }}
                        </uni-label>
                        <mat-form-field>
                          <mat-select [disableOptionCentering]="true" [placeholder]="'label.batchSizePlaceholder' | translate"
                                      formControlName="batchSize">
                            <mat-option
                              *ngFor="let size of batchSizes" [value]="size">
                              {{ size }} {{ 'label.thresholdOptionSuffix' | translate }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </uni-form-field>
                    </uni-form-container>
                  </div>
                </div>
              </div>
              <uni-separator class="mt-0"></uni-separator>

              <fs-payload [eventForm]="eventControl"
                          [payload]="getPayloadByEventId(eventControl.value.eventId)"></fs-payload>

              <div class="content-box mt-4">
                <fs-key-value-variable formControlName="customHeaders"></fs-key-value-variable>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

    </uni-box-content>

    <uni-box-footer>
      <uni-buttons>
        <uni-button (click)="onCancel()" [isMargin]="true" [style]="'secondary'" type="button">
          {{ 'label.cancel' | translate }}
        </uni-button>
        <uni-button [disabled]="form.invalid" [isMargin]="true" type="submit">
          {{ 'webhook.form.submitButton' | translate }}
        </uni-button>
      </uni-buttons>
    </uni-box-footer>
  </uni-box>
</form>

<fs-webhook-test-modal
  (isActiveChange)="toggleModal()"
  [isActive]="isModalActive"
></fs-webhook-test-modal>

<fs-webhook-secret-modal
  (isActiveChange)="toggleSecretModal()"
  [isActive]="isSecretModalActive"
  [secret]="secret"
></fs-webhook-secret-modal>

<uni-loader *ngIf="isLoaderActive$ | async"></uni-loader>
