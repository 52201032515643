import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import { set } from 'lodash';

enum PayloadEntryType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
  Object = 'object',
  Array = 'array',
  Extra = 'extra'
}

interface IPayloadPair {
  key: string | number;
  value: string | number | boolean | IPayloadPair[];
  path: string;
  type: PayloadEntryType;
  formGroup?: FormGroup;
  hidden?: boolean;
}

const SELF = 'self__';
const EXTRA = 'extra__';

@Component({
  selector: 'fs-payload',
  templateUrl: './payload.component.html',
  styleUrls: ['./payload.component.scss']
})
export class PayloadComponent implements OnChanges {
  @Input() payload?: string;
  @Input() eventForm: FormGroup;
  form?: FormGroup;
  payloadPairs?: IPayloadPair[];
  flattenedPairs?: IPayloadPair[];
  payloadEntryType = PayloadEntryType;
  singleChildParentSubscriptions: Subscription[] = [];
  showSelectedOnly = false;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {
  }

  get isSomeSelected(): boolean {
    return this.getIsSomeSelected(this.payloadPairs);
  }

  get isAllSelected(): boolean {
    return this.getIsAllSelected(this.payloadPairs);
  }

  private get excludePayloadAttributes(): string[] {
    return this.eventForm.controls.excludePayloadAttributes.value?.filter(Boolean) || [];
  }

  private get includePayloadAttributes(): Record<string, string> {
    return this.eventForm.controls.includePayloadAttributes.value || {};
  }

  getIsSomeSelected(pairs?: IPayloadPair[]): boolean {
    return pairs?.some(pair => this.isPairSelected(pair)) && !this.getIsAllSelected(pairs);
  }

  getIsAllSelected(pairs?: IPayloadPair[]): boolean {
    return pairs?.every(pair => this.isPairSelected(pair));
  }

  isPairSelected(pair: IPayloadPair): boolean {
    return this.getFormGroup(pair)?.controls.selected.value;
  }

  ngOnChanges(): void {
    if (this.payload) {
      const obj = JSON.parse(this.payload) as Record<string, any>;
      Object.entries(this.includePayloadAttributes).filter(([_, value]) => !/\{\{.+}}/.test(value)).forEach(([key, value]) => {
        set(obj, key, `${EXTRA}${value}`);
      });
      this.payloadPairs = this.getPairs(obj);
      this.initForm();
    }
  }

  getFormGroup(pair: IPayloadPair): FormGroup {
    let path = pair.path;
    if (this.isObjectOrArray(pair)) {
      path += `.${SELF}`;
    }
    return this.form?.get(path) as FormGroup;
  }

  isObjectOrArray(pair: IPayloadPair): boolean {
    return [PayloadEntryType.Object, PayloadEntryType.Array].includes(pair.type);
  }

  handleSelectAll($event: boolean, pairs = this.payloadPairs): void {
    pairs?.forEach(pair => {
      if (this.isObjectOrArray(pair)) {
        this.handleSelectAll($event, pair.value as IPayloadPair[]);
      }
      this.getFormGroup(pair)?.controls.selected.setValue($event);
    });
  }

  handleSomeSelected($event: boolean, pair: IPayloadPair): void {
    this.getFormGroup(pair)?.controls.selected.setValue($event || this.getIsAllSelected(pair.value as IPayloadPair[]));
  }

  getPropClass(pair: IPayloadPair): string {
    return this.getFormGroup(pair)?.controls.selected.value ? 'selected' : 'not-selected';
  }

  addPair(pairs: IPayloadPair[]): void {
    const parentPath = this.getParentPath(pairs[0]);
    const parentFormGroup = parentPath ? this.form?.get(parentPath) as FormGroup : this.form;
    const key = uuid.v4();
    const [pair] = this.getPairs({[key]: ''}, parentPath);
    pair.type = PayloadEntryType.Extra;
    parentFormGroup.addControl(key, this.createFormGroup(pair));
    pairs.push(pair);
    this.cdr.detectChanges();
    document.getElementById(pair.path)?.focus();
    this.handleFlattenPairs();
  }

  toggleShowSelectedOnly(): void {
    this.showSelectedOnly = !this.showSelectedOnly;
    this.flattenedPairs?.forEach(pair => pair.hidden = this.showSelectedOnly ? !pair.formGroup.controls.selected.value : false);
  }

  private flattenPairs(pairs = this.payloadPairs): IPayloadPair[] {
    if (!this.form) {
      return [];
    }
    return pairs?.flatMap(pair => {
      pair.formGroup = this.getFormGroup(pair);
      if (this.isObjectOrArray(pair)) {
        return [pair, ...this.flattenPairs(pair.value as IPayloadPair[])];
      }
      return [pair];
    });
  }

  private getPairs(obj: Record<string, any>, parentPath = ''): IPayloadPair[] {
    if (!obj) {
      return [];
    }
    return Object.entries(obj).map(([key, originalValue]) => {
      let value = originalValue;
      let type = typeof value as PayloadEntryType;
      const path = `${parentPath ? parentPath + '.' : ''}${!parentPath || isNaN(+key) ? key : '[' + key + ']'}`;
      if (type === PayloadEntryType.Object) {
        if (!value) {
          type = PayloadEntryType.String;
        } else {
          if (Array.isArray(value)) {
            type = PayloadEntryType.Array;
          }
          value = this.getPairs(value, path);
        }
      } else if (type === PayloadEntryType.String && value.startsWith(EXTRA)) {
        type = PayloadEntryType.Extra;
        value = value.replace(new RegExp(EXTRA, 'g'), '');
      }
      return {key, value, type, path};
    });
  }

  private initForm(): void {
    if (!this.payloadPairs) {
      return;
    }
    this.form = this.getPairsControls(this.payloadPairs);
    this.form.valueChanges.subscribe(() => {
      this.handleAttributes();
    });
    this.handleFlattenPairs();
  }

  private handleFlattenPairs(): void {
    this.singleChildParentSubscriptions.forEach(s => s.unsubscribe());
    this.singleChildParentSubscriptions = [];
    this.flattenedPairs = this.flattenPairs();
    this.flattenedPairs.filter(pair => this.isObjectOrArray(pair) && (pair.value as IPayloadPair[]).length === 1)
      .forEach(pair => {
        this.singleChildParentSubscriptions.push(
          this.getFormGroup((pair.value as IPayloadPair[])[0]).controls.selected.valueChanges.subscribe(value => {
            pair.formGroup.controls.selected.setValue(value);
          }));
      });
  }

  private createFormGroup(pair: IPayloadPair): FormGroup {
    const isAddPair = uuid.validate(pair.key as string);
    const formGroup = new FormGroup({
      key: new FormControl(isAddPair ? '' : pair.key),
      value: new FormControl(pair.type === PayloadEntryType.Extra ? pair.value : undefined),
      selected: new FormControl(isAddPair)
    });

    formGroup.controls.selected.valueChanges.subscribe(value => {
      const controls = [
        formGroup.controls.key,
        formGroup.controls.value,
      ];
      controls.forEach(control => {
        if (value) {
          control.enable();
        } else {
          control.disable();
        }
      });
      pair.hidden = !value && this.showSelectedOnly;
    });

    return formGroup;
  }

  private getPairsControls(pairs: IPayloadPair[], parent?: IPayloadPair): FormGroup {
    const selfFormGroup = (pair: IPayloadPair) => {
      const formGroup = this.createFormGroup(pair);

      let selected = !this.excludePayloadAttributes.some(path => path === pair.path || path === this.getParentPath(pair));
      let keyValue;
      if (!selected) {
        [keyValue] = Object.entries(this.includePayloadAttributes).find(([_, value]) =>
          value === `{{${pair.path}}}`
        ) || [];
        selected = !!keyValue;
      }

      if (keyValue) {
        const key = keyValue.split('.').pop();
        formGroup.controls.key.setValue(key);
      }
      formGroup.controls.selected.setValue(selected);

      return formGroup;
    };
    const start = {};
    if (parent) {
      start[SELF] = selfFormGroup(parent);
    }
    return new FormGroup(pairs.reduce((acc, pair) => {
      acc[pair.key] = this.isObjectOrArray(pair)
        ? this.getPairsControls(pair.value as IPayloadPair[], pair)
        : selfFormGroup(pair);
      return acc;
    }, start));
  }

  private handleAttributes(): void {
    this.eventForm?.controls.excludePayloadAttributes?.setValue(this.getExcludedPairs());
    this.eventForm?.controls.includePayloadAttributes?.setValue(this.getIncludedPairs());
  }

  private getParentPath(pair: IPayloadPair, childToAdd?: string): string {
    const path = pair.path.split('.');
    path.pop();
    if (childToAdd) {
      path.push(childToAdd);
    }
    return path.join('.');
  }

  private getExcludedPairs(): string[] {
    return this.flattenedPairs
      ?.reduce((acc, pair) => {
        const keyControlValue = pair.formGroup.controls.key.value;
        if ((!pair.formGroup.controls.selected.value || pair.key !== keyControlValue)
          && (!pair.path.includes('.') || !acc.some(path => path === this.getParentPath(pair)))
          && !uuid.validate(pair.key as string)
        ) {
          acc.push(pair.path);
        }
        return acc;
      }, [] as string[]);
  }

  private getIncludedPairs(): Record<string, string> {
    return this.flattenedPairs
      ?.reduce((acc, pair) => {
        const keyControlValue = pair.formGroup.controls.key.value;
        const valueControlValue = pair.formGroup.controls.value.value;
        if (pair.formGroup.controls.selected.value && (pair.key !== keyControlValue || pair.type === PayloadEntryType.Extra)) {
          const parentPath = this.getParentPath(pair);
          const [parentNewPath] = Object.entries(acc).find(([_, value]) => value === `{{${parentPath}}}`) || [];
          let newPath = this.getParentPath(pair, keyControlValue);
          if (parentNewPath) {
            newPath = newPath.replace(parentPath, parentNewPath);
          }
          acc[newPath] = valueControlValue || `{{${pair.path}}}`;
        }
        return acc;
      }, {} as Record<string, string>);
  }
}
