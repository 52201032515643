import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'fs-webhook-test-modal',
  templateUrl: './webhook-test-modal.component.html',
})
export class WebhookTestModalComponent {
  @Input() isActive = false;
  @Output() isActiveChange = new EventEmitter();
  @Output() submitChange = new EventEmitter();

  hideModal(): void {
    this.isActiveChange.emit(false);
  }

  onSubmit(): void {
    this.submitChange.emit(true);
    this.hideModal();
  }
}
