<uni-modal
  [isCloseIcon]="false"
  [isOverlayAction]="false"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-modal-content>
    {{ 'webhook.secret.description' | translate }}
    <div class="input-group mt-4">
      <input #formInputText class="form-control border-right-0 secret" readonly="readonly" type="text"
             value="{{secret}}">
      <span class="input-group-append">
        <button [ngxClipboard]="formInputText" class="btn border border-left-0 secret-button" type="button">
          <i class="far fa-copy"></i></button>
      </span>
    </div>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button (click)="onSubmit()">
      {{ 'webhook.secret.ok' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
