import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { StoreModule } from '@ngrx/store';
import { environment } from '@env/environment';

import {
  HttpQueryModule,
  SettingFactory,
  SettingsHttpService, SettingsService,
  TokenInterceptorFactory, UniAuthRepository, UniAuthService,
  UniSharedModule
} from '@unifonic/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LazyTranslateLoader } from './app-translate-loader';

import { ClipboardModule } from 'ngx-clipboard';
import { AuthModule } from '@auth/auth.module';
import { effectsModule, reducerProvider, storeDevtoolsModule, storeModule } from './store';
import { WebhooksModule } from '@webhooks/webhooks.module';
import { LogoutComponent } from './logout/logout.component';

const modules = [
  AuthModule,
  HttpClientModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  StoreModule,
  UniSharedModule,
  HttpQueryModule,
  WebhooksModule,
  LayoutModule,
  ...storeModule,
  ...effectsModule,
  ...storeDevtoolsModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: LazyTranslateLoader
    }
  }),
];


@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent
  ],
  imports: [
    ClipboardModule,
    ...modules
  ],
  exports: [modules],
  providers: [
    CookieService,
    reducerProvider,
    {
      provide: 'environment',
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: TokenInterceptorFactory(),
      multi: true,
      deps: [UniAuthService, UniAuthRepository, SettingsService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: SettingFactory,
      deps: [SettingsHttpService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
