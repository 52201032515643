import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

@Component({
  selector: 'fs-key-value-variable',
  templateUrl: './key-value-variable.component.html',
  styleUrls: ['./key-value-variable.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => KeyValueVariableComponent),
      multi: true
    }
  ]
})
export class KeyValueVariableComponent implements OnInit, ControlValueAccessor {

  public form: FormGroup;
  @Input() label = 'label.customHeaders';
  private value: object;

  constructor(private formBuilder: FormBuilder) {
  }

  get variables() {
    return this.form.get('variables') as FormArray;
  }

  ngOnInit(): void {
    this.initForm();
    this.form.valueChanges.subscribe(values => {
      this.value = values.variables.reduce((arr, object) => ({...arr, [object.key]: object.value}), {});
      this.onChange(this.value);
    });
  }

  onChange: any = () => {
  };

  propagateChange: any = () => {
  };

  writeValue(value: object): void {
    this.value = value;
    while (this.variables.length !== 0) {
      this.variables.removeAt(0);
    }
    for (const key in value) {
      this.variables.push(this.formBuilder.group({
        key: [key, Validators.required],
        value: [value[key], Validators.required]
      }));
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateChange = fn;
  }

  public onDrop(event, control: AbstractControl): void {
    const data = event.item.data;
    control.patchValue(control.value + '{{' + data + '}}');
  }

  public removeVariable(index): void {
    this.variables.removeAt(index);
  }

  public addVariable(value?: { key: string, value: string }) {
    const group = this.formBuilder.group(
      {
        key: [value?.key || '', Validators.required],
        value: [value?.value || '', Validators.required]
      }
    );
    this.variables.push(group);
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      variables: this.formBuilder.array([
        this.formBuilder.group({
          key: ['', Validators.required],
          value: ['', Validators.required]
        })
      ])
    });

  }
}
