import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UniNavFacade } from '../../../uni-nav';
import { UniAuthFacade } from '../../../uni-auth/shared/uni-auth.facade';
import { AccountsFacade } from '../../../../shared';

@Component({
  selector: 'uni-uni-top-bar-troubleshoot-confirm',
  templateUrl: './uni-top-bar-troubleshoot-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniTopBarTroubleshootConfirmComponent {
  isTroubleshootModalActive$ = this.uniNavFacade.isTroubleshootConfirmationOpen$;

  get confirmLabel(): string {
    return this.uniAuthFacade.user.account.troubleshootingEnabled
      ? 'troubleshooting.action.disableTroubleshootMode'
      : 'troubleshooting.action.enableTroubleshootMode';
  }

  constructor(
    private uniNavFacade: UniNavFacade,
    private uniAuthFacade: UniAuthFacade,
    private accountsFacade: AccountsFacade,
  ) {}

  closeTroubleshootModal(): void {
    this.uniNavFacade.closeTroubleshootConfirmation();
  }

  confirmTroubleshootMode(): void {
    const accountId = this.uniAuthFacade.user.account.id;
    this.accountsFacade.putAccountTroubleshootingMode(accountId, !this.uniAuthFacade.user.account.troubleshootingEnabled);
  }
}
