import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {WebhookFormFactory} from "@webhooks/shared/webhooks-form.factory";
import {WebhooksFacade} from "@webhooks/shared/webhooks.facade";
import {ActionsSubject} from "@ngrx/store";
import {AuthFacade} from "@auth/shared/auth.facade";
import {ofType} from "@ngrx/effects";
import * as WebhooksActions from "@webhooks/store/webhooks.actions";

@Component({
  selector: 'fs-webhook-secret-modal',
  templateUrl: './webhook-secret-modal.component.html',
})
export class WebhookSecretModalComponent {
  @Input() isActive = false;
  @Input() secret ;
  @Output() isActiveChange = new EventEmitter();
  @Output() submitChange = new EventEmitter();

  constructor(
    private router: Router,
    private webhooksFacade: WebhooksFacade,
  ) {
  }
  hideModal(): void {
    this.isActiveChange.emit(false);
  }

  onSubmit(): void {
    this.router.navigate(['/webhook/list']);
    this.hideModal();
  }
}
