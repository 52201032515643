import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AuthState } from '../store/auth.state';
import * as selectors from '../store/auth.selectors';
import * as authActions from '../store/auth.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthFacade {
  userData$ = this.store.pipe(select(selectors.selectUserData));

  constructor(private store: Store<AuthState>) {}

  setUserData() {
    this.store.dispatch(new authActions.SetUserData());
  }
}
