export interface Webhook {
  description: string;
  // endpointUrl: string;
  webhookEndpointUrlList: string[];
  id: number;
  name: string;
  secretKey: any;
  status: boolean;
  customPayload: any;
  payload: string;
  productEvents: WebhookProductEvent[] | null;
}

export interface WebhookProductEvent {
  eventId: number;
  productId: number;
  identifierKey?: string;
  identifierValue?: string;
  excludePayloadAttributes?: string[];
  includePayloadAttributes?: Record<string, string>;
  customHeaders?: { [key: string]: any }[];
  batchingConfig?: BatchingConfig;
}

export interface BatchingConfig {
  batchSize: number;
  batchLimit: number;
}

export interface WebhooksResponse {
  content?: Webhook[];
  totalElements?: number;
  totalPages?: number;
}

export enum WebhooksFilters {
  name = 'name'
}

export type WebhooksParams = {
  [K in WebhooksFilters]?: number | string;
};

export enum WebhooksListTableColumns {
  name = 'name',
  endpointUrl = 'endpointUrl',
  description = 'description',
  status = 'status',
  actions = 'actions',
}

export interface Product {
  productId: number;
  productName: string;
  productDescription: string;
  identifierKeys: string;
}

export interface WebhookEvent {
  eventId: number;
  product: Product;
  eventName: string;
  payload: string;
}

export interface WebhookQueuedEvents {
  queuedEvents: number;
  estimatedDeliveryTime: string;
  estimatedDeliveryTimeSeconds: number;
}
