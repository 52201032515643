import { Action } from '@ngrx/store';
import { Product, Webhook, WebhookEvent, WebhookQueuedEvents, WebhooksParams } from '../shared/webhooks.model';

export const GET_WEBHOOKS = '[Webhooks] GET_WEBHOOKS';
export const GET_WEBHOOKS_SUCCESS = '[Webhooks] GET_WEBHOOKS_SUCCESS';
export const SET_WEBHOOKS_AMOUNT = '[Webhooks] SET_WEBHOOKS_AMOUNT';

export const GET_WEBHOOK = '[Webhooks] GET_WEBHOOK';
export const GET_WEBHOOK_SUCCESS = '[Webhooks] GET_WEBHOOK_SUCCESS';

export const GET_PRODUCTS = '[Webhooks] GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = '[Webhooks] GET_PRODUCTS_SUCCESS';

export const GET_EVENTS = '[Webhooks] GET_EVENTS';
export const GET_EVENTS_SUCCESS = '[Webhooks] GET_EVENTS_SUCCESS';

export const GET_QUEUED_EVENTS = '[Webhooks] GET_QUEUED_EVENTS';
export const GET_QUEUED_EVENTS_SUCCESS = '[Webhooks] GET_QUEUED_EVENTS_SUCCESS';

export const POST_WEBHOOK = '[Webhooks] POST_WEBHOOK';
export const POST_WEBHOOK_SUCCESS = '[Webhooks] POST_WEBHOOK_SUCCESS';

export const PUT_WEBHOOK = '[Webhooks] PUT_WEBHOOK';
export const PUT_WEBHOOK_SUCCESS = '[Webhooks] PUT_WEBHOOK_SUCCESS';

export const DELETE_WEBHOOK = '[Webhooks] DELETE_WEBHOOK';
export const DELETE_WEBHOOK_SUCCESS = '[Webhooks] DELETE_WEBHOOK_SUCCESS';

export const TEST_WEBHOOK = '[Webhooks] TEST_WEBHOOK';
export const TEST_WEBHOOK_SUCCESS = '[Webhooks] TEST_WEBHOOK_SUCCESS';

export class GetProducts implements Action {
  readonly type = GET_PRODUCTS;

  constructor() { }
}

export class GetProductsSuccess implements Action {
  readonly type = GET_PRODUCTS_SUCCESS;

  constructor(public response: Product[]) { }
}

export class GetEvents implements Action {
  readonly type = GET_EVENTS;

  constructor() { }
}

export class GetEventsSuccess implements Action {
  readonly type = GET_EVENTS_SUCCESS;

  constructor(public response: WebhookEvent[]) { }
}

export class GetQueuedEvents implements Action {
  readonly type = GET_QUEUED_EVENTS;

  constructor(public webhookId: string) { }
}

export class GetQueuedEventsSuccess implements Action {
  readonly type = GET_QUEUED_EVENTS_SUCCESS;

  constructor(public response: WebhookQueuedEvents) { }
}

export class GetWebhooks implements Action {
  readonly type = GET_WEBHOOKS;

  constructor(public params?: WebhooksParams) { }
}

export class GetWebhooksSuccess implements Action {
  readonly type = GET_WEBHOOKS_SUCCESS;

  constructor(public response: Webhook[]) { }
}

export class SetWebhooksAmount implements Action {
  readonly type = SET_WEBHOOKS_AMOUNT;

  constructor(public amount: number) { }
}

export class GetWebhook implements Action {
  readonly type = GET_WEBHOOK;

  constructor(public webhookId?: string) { }
}

export class GetWebhookSuccess implements Action {
  readonly type = GET_WEBHOOK_SUCCESS;

  constructor(public webhook: Webhook) { }
}

export class PostWebhook implements Action {
  readonly type = POST_WEBHOOK;

  constructor(public accountId: string, public payload: Webhook) { }
}

export class PostWebhookSuccess implements Action {
  readonly type = POST_WEBHOOK_SUCCESS;

  constructor(public response : Webhook) { }
}

export class PutWebhook implements Action {
  readonly type = PUT_WEBHOOK;

  constructor(public accountId: string, public payload: Webhook, public webhookId: string) { }
}

export class PutWebhookSuccess implements Action {
  readonly type = PUT_WEBHOOK_SUCCESS;

  constructor() { }
}

export class DeleteWebhook implements Action {
  readonly type = DELETE_WEBHOOK;

  constructor(public webhookId: string) { }
}

export class DeleteWebhookSuccess implements Action {
  readonly type = DELETE_WEBHOOK_SUCCESS;

  constructor() { }
}

export class TestWebhook implements Action {
  readonly type = TEST_WEBHOOK;

  constructor(public accountId: string, public eventId: string, public identifierValue?: string) { }
}

export class TestWebhookSuccess implements Action {
  readonly type = TEST_WEBHOOK_SUCCESS;

  constructor() { }
}

export type All =
  | GetWebhooks
  | GetWebhooksSuccess
  | GetProducts
  | GetProductsSuccess
  | GetEvents
  | GetEventsSuccess
  | SetWebhooksAmount
  | GetWebhook
  | GetWebhookSuccess
  | GetQueuedEvents
  | GetQueuedEventsSuccess
  | PostWebhook
  | PostWebhookSuccess
  | PutWebhook
  | PutWebhookSuccess
  | DeleteWebhook
  | DeleteWebhookSuccess;
