import { createFeatureSelector, createSelector } from '@ngrx/store';

import { AuthState } from './auth.state';

export const selectAuthFeature = createFeatureSelector<AuthState>('auth');

export const selectUserData = createSelector(
  selectAuthFeature,
  (state: AuthState) => state.userData,
);
