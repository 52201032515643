import { Component, Input } from '@angular/core';

import { UniSnackbarFacade } from '../../../uni-snackbar/shared/uni-snackbar.facade';

@Component({
  selector: 'uni-clipboard',
  templateUrl: './uni-clipboard.component.html',
  styleUrls: ['./uni-clipboard.component.scss'],
})
export class UniClipboardComponent {
  @Input() text: string;
  @Input() emptyTextReplacement = '-';
  @Input() length = 5;
  @Input() isIcon = true;
  @Input() isActive = false;

  get isMoreIcon(): boolean {
    return this.length && this.text && this.length < this.text.length;
  }

  constructor(private uniSnackbarFacade: UniSnackbarFacade) {}

  copyInputMessage(inputElement): void {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.uniSnackbarFacade.show('success', 'snackbar.successCopyId');
  }

  toggleText(): void {
    this.isActive = !this.isActive;
  }

  displayText(): string {
    if (!this.length) {
      return this.text;
    }

    return this.text
      ? (this.isActive ? this.text : this.text.substr(0, this.length))
      : this.emptyTextReplacement;
  }
}
