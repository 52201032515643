import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LottieModule } from 'ngx-lottie';

import { UniBoxModule } from '../uni-box/uni-box.module';
import { UniSnackbarModule } from '../uni-snackbar';
import { UniInfoBoxComponent } from './components/uni-info-box/uni-info-box.component';
import { UniViewComponent } from './components/uni-view/uni-view.component';
import { UniLinkComponent } from './components/uni-link/uni-link.component';
import { UniLoaderComponent } from './components/uni-loader/uni-loader.component';
import { UniMoreListComponent } from './components/uni-more-list/uni-more-list.component';
import { UniLayoutGuestComponent } from './containers/uni-layout-guest/uni-layout-guest.component';
import { UniLayoutLoggedComponent } from './containers/uni-layout-logged/uni-layout-logged.component';
import { LayoutEffects } from './store/uni-layout.effects';
import { layoutReducer } from './store/uni-layout.reducers';
import { UniIconModule } from '../uni-icon/uni-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { HttpQueryModule } from '../../store/http-query/http-query.module';
import { UniNavNewModule } from '../uni-nav-new';
import { UniFooterModule } from '../uni-footer';
import { UniLanguageModule } from '../uni-language';
import player from 'lottie-web';
import { UniLayoutCoreModule } from './uni-layout-core.module';

export function playerFactory(): any {
  return player;
}

const components = [
  UniLoaderComponent,
  UniLinkComponent,
  UniLayoutGuestComponent,
  UniLayoutLoggedComponent,
  UniMoreListComponent,
  UniInfoBoxComponent,
  UniViewComponent,
];

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    UniBoxModule,
    UniSnackbarModule,
    UniNavNewModule,
    UniFooterModule,
    UniIconModule,
    UniLayoutCoreModule,
    TranslateModule,
    HttpQueryModule,
    UniLanguageModule,
    StoreModule.forFeature('layout', layoutReducer),
    EffectsModule.forFeature([LayoutEffects]),
    LottieModule.forRoot({ player: playerFactory }),
  ],
  declarations: [...components],
  exports: [
    ...components,
    UniLayoutCoreModule,
  ],
})
export class UniLayoutModule { }
