import { createSelector, createFeatureSelector } from '@ngrx/store';

import { UniDataState, UNI_DATA_STATE } from './uni-data.state';

export const selectUniDataFeature = createFeatureSelector<UniDataState>(UNI_DATA_STATE);

export const selectOperators = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.operators,
);

export const selectOperatorsQuery = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.getOperatorsQuery,
);

export const selectProviders = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.providers,
);

export const selectProvidersQuery = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.getProvidersQuery,
);

export const selectAccounts = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.accounts,
);

export const selectAccountsQuery = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.getAccountsQuery,
);

export const selectAllAccounts = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.accounts,
);

export const selectAllAccountsQuery = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.getAllAccountsQuery,
);

export const selectSubAccounts = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.subAccounts,
);

export const selectSubAccountsQuery = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.getSubAccountsQuery,
);

export const selectSenderNames = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.senderNames,
);

export const selectSenderNamesQuery = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.getSenderNamesQuery,
);

export const selectAccountsChoice = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.accountsChoice,
);

export const selectProvidersChoice = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.providersChoice,
);

export const selectSendersChoice = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.sendersChoice,
);

export const selectOperatorsChoice = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.operatorsChoice,
);

export const selectActivePackages = createSelector(
  selectUniDataFeature,
  (state: UniDataState) => state.activePackages,
);
