import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthFacade } from '@auth/shared/auth.facade';
import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { WebhookFormFactory } from '@webhooks/shared/webhooks-form.factory';
import { WebhooksFacade } from '@webhooks/shared/webhooks.facade';
import * as WebhooksActions from '@webhooks/store/webhooks.actions';
import { Subject } from 'rxjs';
import { skipUntil, take, takeUntil } from 'rxjs/operators';
import { Product, Webhook, WebhookQueuedEvents } from '@webhooks/shared/webhooks.model';
import { HttpQueryFacade, UniTextColor, UniTextWeight } from '@unifonic/common';
import { WhatsappApplicationModel } from '@webhooks/shared/whatsapApplications.model';
import ConversationService from '@webhooks/modules/webhook-form/shared/conversation-service';
import { WEBHOOKS_KEY } from '@webhooks/store';

const eventTypesForTranslation = ['dlr'];

@Component({
  selector: 'fs-webhook-form',
  templateUrl: './webhook-form.component.html',
  styleUrls: ['./webhook-form.component.scss']
})
export class WebhookFormComponent implements OnInit, OnDestroy {
  public form: FormGroup = this.webhookFormFactory.form;
  public webhookId: string = null;
  public products?: Product[];
  public eventsList = this.webhooksFacade.events$;
  public isModalActive = false;
  public isSecretModalActive = false;
  public secret: string = null;
  public eventPayloadMap: Map<number, string> = new Map();
  public showEvent: boolean[] = this.productEvents?.controls.map(() => false) || [false];
  public whatsappApplications?: WhatsappApplicationModel[];
  public whatsAppProductId = null;
  public showIdentifiers = false;
  public batchLimits = [500, 1000, 5000];
  public batchSizes = [50, 100, 200, 250];
  public queuedEvents: WebhookQueuedEvents;
  private unsubscribe$ = new Subject();
  private accountId: string;
  private webhook$ = new Subject();
  isLoaderActive$ = this.httpQueryFacade.isInProgress$(WEBHOOKS_KEY);

  uniTextWeight = UniTextWeight;
  uniTextColor = UniTextColor;

  constructor(
    private webhookFormFactory: WebhookFormFactory,
    private webhooksFacade: WebhooksFacade,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private actionsSubj: ActionsSubject,
    private authFacade: AuthFacade,
    private httpQueryFacade: HttpQueryFacade,
    private conversationService: ConversationService,
  ) {
    this.actionsSubj.pipe(
      ofType<WebhooksActions.TestWebhook>(WebhooksActions.TEST_WEBHOOK)
    ).subscribe(() => {
      this.isModalActive = true;
    });
  }

  public get status(): boolean {
    return this.form.get('status').value;
  }

  get isAddButtonDisabled(): boolean {
    return !this.endpointUrlControl.value || this.endpointUrlControl.invalid;
  }

  get endpointUrlControl(): FormControl {
    return this.form.get('endpointUrl') as FormControl;
  }

  get webhookEndpointUrlsControl(): FormControl {
    return this.form.get('webhookEndpointUrlList') as FormControl;
  }
  get productEvents(): FormArray {
    return this.form?.get('productEvents') as FormArray;
  }

  batchingControl(index: number): FormControl {
    return this.productEvents?.controls[index].get('batching') as FormControl;
  }

  batchingConfigControl(index: number): FormControl {
    return this.productEvents?.controls[index].get('batchingConfig') as FormControl;
  }

  ngOnInit(): void {
    this.loadProducts();
    this.loadQueuedEvents();
    this.initForm();
    this.authFacade.userData$.pipe(
      take(1)
    ).subscribe(data => {
      this.accountId = data.accountId;
    });
    this.webhooksFacade.webhook$
      .pipe(
        skipUntil(this.webhook$), // To fix showing secret modal if visit create new webhook twice
        takeUntil(this.unsubscribe$)
      )
      .subscribe(webhook => {
        if (webhook) {
          this.secret = webhook.secretKey;
        }
        if (!this.webhookId && this.secret) {
          this.toggleSecretModal();
        }
      });
    this.conversationService.getWhatsAppApplications().subscribe(res => {
      this.whatsappApplications = [...res];
    });
  }

  translationsKey(value: string): string {  
    if (eventTypesForTranslation.includes(value.toLocaleLowerCase())) {
      return `webhook.eventTypes.${value.toLowerCase()}`;
    }
    return value;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  batchingClicked(index: number): void {
    if(this.batchingControl(index).value) {
      this.webhookFormFactory.addBatchingConfig(index);
    } else {
      this.webhookFormFactory.removeBatchingConfig(index);
    }
  }

  toggleEvent(i = 0): void {
    this.showEvent[i] = !this.showEvent[i];
    if (this.productEvents?.controls[i].get('productId').value === this.whatsAppProductId) {
      this.showIdentifiers = true;
    }
  }

  toggleModal(): void {
    this.isModalActive = !this.isModalActive;
  }

  toggleSecretModal(): void {
    this.isSecretModalActive = !this.isSecretModalActive;
  }

  testWebhook(eventId: string, identifierValue?: string): void {
    this.webhooksFacade.testWebhook(this.accountId, eventId, identifierValue);
  }

  kFormatter(num: number): string {
    return num > 999 ? (num / 1000) + 'k' : num.toString();
  }

  onSubmit(): void {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      control.markAsTouched({onlySelf: true});
      control.markAsDirty({onlySelf: true});
      control.updateValueAndValidity({onlySelf: true});
    });
    this.addUrl();
    const value = JSON.parse(JSON.stringify(this.form.value));
    this.webhook$.next();

    if (this.webhookId) {
      this.webhooksFacade.putWebhook(this.accountId, value, this.webhookId);
    } else {
      this.webhooksFacade.postWebhook(this.accountId, value);
    }
  }

  onCancel(): void {
    this.router.navigate(['/webhook/list']);
  }

  addEvent(): void {
    this.webhookFormFactory.addEvent();
  }

  removeEvent(index: number): void {
    this.webhookFormFactory.removeEvent(index);
  }

  eventChange(event: any): void {
    this.eventPayloadMap.set(event.eventId, event.payload);
  }

  getPayloadByEventId(eventId: any): string {
    return this.eventPayloadMap.get(eventId);
  }

  addUrl(): void {
    const endpoint = this.endpointUrlControl.value;
    if (!endpoint || this.endpointUrlControl.invalid) {
      return;
    }
    this.webhookEndpointUrlsControl.setValue([...(this.webhookEndpointUrlsControl.value ?? []), endpoint]);
    this.endpointUrlControl.setValue('');
  }

  productChange(product: Product, $event: any): void {
    if (!$event.isUserInput) {
      return;
    }
    this.showIdentifiers = product.productId === this.whatsAppProductId;
  }

  updateIdentifier(identifierKey: string, i: number): void {
    this.productEvents?.controls[i].get('identifierKey').patchValue(identifierKey);
  }

  private loadProducts(): void {
    this.webhooksFacade.products$.subscribe(products => {

      this.products = products;
      if (products) {
        const whatsAppProduct = products.filter((p) => {
          return 'conversation' === p.productName.toLowerCase();
        });
        this.whatsAppProductId = whatsAppProduct[0]?.productId;
      }
    });
  }

  private loadQueuedEvents(): void {
    this.webhooksFacade.queuedEvents$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(queuedEvents => {
        this.queuedEvents = queuedEvents;
      });
  }

  private initForm(): void {
    this.webhooksFacade.getProducts();
    this.webhooksFacade.getEvents();
    this.form.reset();
    (this.form.controls.productEvents as FormArray).clear();
    this.form.controls.status.setValue(false);
    this.webhookId = this.activatedRoute.snapshot.params.webhookId;
    if (this.webhookId) {
      this.webhooksFacade.getWebhook(this.webhookId);
      this.webhooksFacade.getQueuedEvents(this.webhookId);
      this.webhookFormFactory.fetchValues();
    } else {
      this.webhookFormFactory.addEvent();
    }
  }
}
