import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { pairwise, startWith } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../../utils/auto-unsubscribe';

import { times } from './campaign-time.repository';
import { getSmsRoundedTimes, getVoiceRoundedTimes, getVoiceBypassTimes } from './campaign-time.utils';
import { DateUtils } from '../../../../utils';

type CampaignDate = Date | moment.Moment;

@Component({
  selector: 'cc-campaign-time',
  templateUrl: './campaign-time.component.html',
})
export class CampaignTimeComponent extends AutoUnsubscribe implements OnInit {
  @Input() form: FormGroup;
  @Input() timezone: string;
  @Input() isVoice = false;
  @Input() isBypassTime = false;
  @Input() hasParentAccount = false;
  shownTimes: string[] = [];

  get timeControl(): FormControl {
    return this.form.get('time') as FormControl;
  }

  get dateControl(): FormControl {
    return this.form.get('date') as FormControl;
  }

  constructor() {
    super();
  }

  ngOnInit() {
    this.shownTimes = this.getTimes();
    this.timeControl.setValue(this.shownTimes[0]);
    this.setValidators();

    this.observeDateControValueChange();
  }

  private getTimes(): string[] {
    const now = DateUtils.getCurrentDate(this.timezone);
    const isTodaySelected = this.isSameDay(this.dateControl.value, now);

    return this.isVoice ? this.getVoiceTimes(now, isTodaySelected) : this.getSmsTimes(now, isTodaySelected);
  }

  private getSmsTimes(now: moment.Moment, isTodaySelected: boolean): string[] {
    return isTodaySelected ? getSmsRoundedTimes(now) : times;
  }

  private getVoiceTimes(now: moment.Moment, isTodaySelected: boolean): string[] {
    return this.isBypassTime && this.hasParentAccount
      ? getVoiceBypassTimes(now, isTodaySelected)
      : getVoiceRoundedTimes(this.timezone, now, isTodaySelected);
  }

  private setValidators(): void {
    this.timeControl.setValidators(Validators.required);
    this.timeControl.updateValueAndValidity();
  }

  private observeDateControValueChange(): void {
    const initialDateControlValue = this.dateControl.value;
    this.subs.sink = this.dateControl
      .valueChanges
      .pipe(
        startWith(initialDateControlValue),
        pairwise(),
      )
      .subscribe(([previousDate, currentDate]) => {
        this.updateShownTimesIfNeeded(previousDate, currentDate);
      });
  }

  private updateShownTimesIfNeeded(previousDate: CampaignDate, currentDate: CampaignDate): void {
    const dateSelectionNotChanged = this.isSameDay(previousDate, currentDate);
    if (dateSelectionNotChanged) {
      return;
    }

    this.shownTimes = this.getTimes();

    const isSelectedTimeInShownTimes = this.shownTimes.indexOf(this.timeControl.value) !== -1;
    if (isSelectedTimeInShownTimes) {
      return;
    }

    this.timeControl.setValue(this.shownTimes[0]);
  }

  private isSameDay(firstDate: CampaignDate, secondDate: CampaignDate): boolean {
    const firstDateFormatted = DateUtils.getDate(firstDate, DateUtils.Format.default);
    const secondDateFormatted = DateUtils.getDate(secondDate, DateUtils.Format.default);

    return firstDateFormatted === secondDateFormatted;
  }
}
