import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UniContainerComponent } from './components/uni-container/uni-container.component';
import { UniSectionComponent } from './components/uni-section/uni-section.component';
import { UniSectionHeaderComponent } from './components/uni-section-header/uni-section-header.component';
import { UniStatusComponent } from './components/uni-status/uni-status.component';
import { UniLabelComponent } from './components/uni-label/uni-label.component';
import { UniTitleContainerComponent } from './components/uni-title-container/uni-title-container.component';
import { UniTitleItemComponent } from './components/uni-title-item/uni-title-item.component';
import { UniPriceBoxComponent } from './components/uni-price-box/uni-price-box.component';
import { UniTagComponent } from './components/uni-tag/uni-tag.component';
import { UniMainContainerComponent } from './components/uni-main-container/uni-main-container.component';
import { UniMainContentComponent } from './components/uni-main-content/uni-main-content.component';
import { UniLayoutContentComponent } from './components/uni-layout-content/uni-layout-content.component';
import { UniOverlayComponent } from './components/uni-overlay/uni-overlay.component';
import { UniValueComponent } from './components/uni-value/uni-value.component';
import { UniInfoComponent } from './components/uni-info/uni-info.component';
import { UniSpaceContainerComponent } from './components/uni-space-container/uni-space-container.component';

const components = [
  UniContainerComponent,
  UniSectionComponent,
  UniSectionHeaderComponent,
  UniStatusComponent,
  UniLabelComponent,
  UniTitleContainerComponent,
  UniTitleItemComponent,
  UniPriceBoxComponent,
  UniTagComponent,
  UniMainContainerComponent,
  UniMainContentComponent,
  UniLayoutContentComponent,
  UniOverlayComponent,
  UniValueComponent,
  UniInfoComponent,
  UniSpaceContainerComponent,
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [...components],
  exports: [...components],
})
export class UniLayoutCoreModule { }
