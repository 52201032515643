import { Injectable } from '@angular/core';
import {SettingsService} from '@shared/settings.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {WhatsappApplicationModel} from '@webhooks/shared/whatsapApplications.model';

@Injectable({
  providedIn: 'root'
})
export default class ConversationService {

  constructor(
    private httpClient: HttpClient,
    private settingsService: SettingsService
  ) {}

  getWhatsAppApplications(): Observable<WhatsappApplicationModel[]> {
    const url = `${this.settingsService.apiUrl}/api/v1/proxy/conversation/applications`;
    return this.httpClient.get<WhatsappApplicationModel[]>(url);
  }

}
