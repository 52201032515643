import { Observable } from 'rxjs';

import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../../utils';
import { PACKAGES_KEY } from '../store/uni-data.state';
import { ActivePackages } from '../model';

@Injectable({
  providedIn: 'root'
})
export class PackagesRepository {
  apiUrl = `${Environment.getMainHost()}/api/v2`;

  constructor(
    private http: HttpClient,
    @Optional() @Inject('environment') public environment,
  ) { }

  getActivePackages(): Observable<ActivePackages> {
    return this.http.get<ActivePackages>(`${this.apiUrl}/packages/active/units`, {
      withCredentials: true,
      headers: {
        queryName: 'getActivePackages',
        queryGroups: [PACKAGES_KEY]
      }
    });
  }
}
