import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'uni-modal-confirmation',
  templateUrl: './uni-modal-confirmation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniModalConfirmationComponent {
  @Input() isActive = false;
  @Input() title: string;
  @Input() description: string;
  @Input() cancelLabel: string;
  @Input() confirmLabel: string;
  @Output() close = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<void>();

  hideModal(): void {
    this.close.emit();
  }

  onConfirm(): void {
    this.confirm.emit();
    this.hideModal();
  }
}
