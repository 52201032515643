import { Injectable } from '@angular/core';
import { SettingsService as CommonSettingsService } from '@unifonic/common';
import { Settings } from '../models/settings';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private commonSettingsService: CommonSettingsService) {
  }

  get settings(): Settings {
    return this.commonSettingsService.settings as unknown as Settings;
  }

  get apiUrl(): string {
    return this.settings.apiBaseUrl;
  }
}
