import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WebhookFormComponent } from './modules/webhook-form/containers/webhook-form/webhook-form.component';
import { WebhookListComponent } from './modules/webhooks-list/containers/webhook-list/webhook-list.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
      },
      {
        path: 'list',
        component: WebhookListComponent
      },
      {
        path: 'new',
        component: WebhookFormComponent
      },
      {
        path: 'edit/:webhookId',
        component: WebhookFormComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class WebhookRoutingModule {}
