import { Component, HostBinding, Input } from '@angular/core';
import { UniNavFacade, FeatureFlagKeys } from '@unifonic/common';

@Component({
  selector: 'fs-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  @HostBinding('class.visible') @Input() isVisible = false;
  @Input() hasBack = false;
  featureFlagKeys = FeatureFlagKeys;

  
  version: string = localStorage.getItem("version") ? localStorage.getItem("version") : "v1";
  navigationVersions = {
    v1: {
      navigations: [
        {
          path: "/webhook/list",
          name: 'sidebar.webhook.list',
          icon: 'compass'
        }
      ]
    }
  }

  navigationList =  this.navigationVersions.v1.navigations;
  
  selectedVersion = [];
  changeNavigationList(version){
    console.log("version",version);
  }

  constructor(private uniNavFacade: UniNavFacade) {}

  goBack(): void {
    this.uniNavFacade.setNavState(false);
  }
}
