<input class="clipboard__input" type="text" [value]="text" #userinput>
{{ displayText() }}
<div class="clipboard__more" *ngIf="isMoreIcon" (click)="toggleText()">
  <ng-container *ngIf="isActive">
    <uni-icon class="clipboard__more-icon" name="arrow-alt-to-left"></uni-icon>
  </ng-container>
  <ng-container *ngIf="!isActive">
    (...)
  </ng-container>
</div>
<uni-icon
  class="clipboard__icon"
  *ngIf="isIcon && text"
  (click)="copyInputMessage(userinput)"
  name="copy"
></uni-icon>
