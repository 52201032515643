<ng-container *ngIf="{ totalItems: (totalItems$ | async) } as data">
  <uni-box [isHidden]="false">
    <uni-box-content [isHorizontalSpace]="false" [isVerticalSpace]="false" borderRadius="all">

        <div #table>
          <uni-table-top-bar>
            <uni-table-top-bar-content>
              <uni-table-top-bar-item>
                <uni-plural [amount]="data.totalItems || 0">
                  {{ data.totalItems || 0 }}
                </uni-plural>
              </uni-table-top-bar-item>
            </uni-table-top-bar-content>
          </uni-table-top-bar>

          <uni-table>

            <table 
              [dataSource]="dataSource$ | async"
              cdk-table>
              <ng-container [cdkColumnDef]="columns.name">
                <th #thead *cdkHeaderCellDef cdk-header-cell>
                  {{ 'table.' + columns.name | translate }}
                  <!-- TODO: uncomment when backend ready -->
                  <!-- <uni-table-filter-search
                    [heading]="'table.' + columns.name | translate"
                    [thead]="thead"
                    [tableLeft]="tableLeft"
                    [controlName]="filters.name"
                  ></uni-table-filter-search> -->
                </th>
                <td *cdkCellDef="let element" cdk-cell>
                  <uni-clipboard
                    [isIcon]="false"
                    [text]="element.name"
                    length="25"
                  ></uni-clipboard>
                </td>
              </ng-container>

              <ng-container [cdkColumnDef]="columns.description">
                <th *cdkHeaderCellDef cdk-header-cell>
                  {{ 'table.' + columns.description | translate }}
                </th>
                <td *cdkCellDef="let element" cdk-cell>
                  <uni-clipboard
                    [isIcon]="false"
                    [text]="element.description"
                    length="25"
                  ></uni-clipboard>
                </td>
              </ng-container>

              <ng-container [cdkColumnDef]="columns.endpointUrl">
                <th *cdkHeaderCellDef cdk-header-cell>
                  {{ 'table.' + columns.endpointUrl | translate }}
                </th>
                <td *cdkCellDef="let element" cdk-cell>
                  <uni-clipboard
                    [isIcon]="false"
                    [text]="element.endpointUrl || element.webhookEndpointUrlList?.toString()"
                    length="25"
                  ></uni-clipboard>
                </td>
              </ng-container>

              <ng-container [cdkColumnDef]="columns.actions">
                <th *cdkHeaderCellDef cdk-header-cell class="cdk-header-actions">
                </th>
                <td *cdkCellDef="let element" cdk-cell class="cdk-actions">
                  <uni-table-action
                    name="edit"
                    [path]="'/webhook/edit/' + element.id"
                  ></uni-table-action>
                  <uni-table-action
                    name="trash"
                    (click)="openConfirmModal(element.id)"
                  ></uni-table-action>
                </td>
              </ng-container>

              <ng-container [cdkColumnDef]="columns.status">
                <th *cdkHeaderCellDef cdk-header-cell>
                  {{ 'table.' + columns.status | translate }}
                  <uni-sort [isInline]="true" [sortBy]="'status'"></uni-sort>
                </th>
                <td *cdkCellDef="let element" cdk-cell>
                  {{ (element.status ? 'webhook.status.active' : 'webhook.status.inactive') | translate}}
                </td>
              </ng-container>

              <tr *cdkHeaderRowDef="displayedColumns" cdk-header-row></tr>
              <tr *cdkRowDef="let row; columns: displayedColumns; let isOdd = odd; let isEven = even; let isLast = last"
                  [ngClass]="{ 'is-odd': isOdd, 'is-even': isEven, 'is-last': isLast }"
                  cdk-row></tr>
            </table>
          </uni-table>
          <uni-table-empty *ngIf="isEmpty()"></uni-table-empty>
        </div>

    </uni-box-content>
  </uni-box>

  <uni-pagination 
    [isAltOrder]="true"
    [items]="data.totalItems"
    [itemsPerPage]="20">
  </uni-pagination>
</ng-container>

<fs-webhook-remove-modal
  (isActiveChange)="toggleModal()"
  (submitChange)="onRemove()"
  [isActive]="isModalActive"
></fs-webhook-remove-modal>
