import { includes, values } from 'lodash';

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UniLanguage } from '../models/index';
import { UniLocalizationService } from './uni-localization.service';
import { CookieService } from 'ngx-cookie-service';
import { UniAuthFacade } from '../../uni-auth/shared/uni-auth.facade';
import { Environment } from '../../../utils';

@Injectable({ providedIn: 'root' })
export class UniLanguageService {
  readonly languages = values(UniLanguage) as UniLanguage[];
  languageUrl = `${Environment.getMainHost()}/profile/switch-locale`;
  LANGUAGE_KEY = 'language';

  constructor(
    private translate: TranslateService,
    private uniLocalizationService: UniLocalizationService,
    private uniAuthFacade: UniAuthFacade,
    private cookieService: CookieService,
  ) { }

  initLanguages(): void {
    const { en } = UniLanguage;
    this.detectLanguage();

    this.translate.addLangs(this.languages);
    this.translate.setDefaultLang(en);

    return;

    // @TODO to be implemented in future sprints
    this.uniLocalizationService.detectLocalization();
  }

  detectLanguage() {
    let language: UniLanguage = UniLanguage.en;

    this.uniAuthFacade.userMe$.subscribe((data) => {
      if (data && data.user) {
        language = data.user.locale as UniLanguage;
      } else {
        language = this.getSelectedLanguage();
      }

      if (Object.values(UniLanguage).includes(language)) {
        this.setLanguage(language);
      } else {
        this.setLanguage(UniLanguage.en);
      }
    });
  }

  getSelectedLanguage(): UniLanguage {
    return this.checkCookies()
      || this.getLanguageFromLocalStorage()
      || UniLanguage.en;
  }

  checkCookies(): UniLanguage {
    return this.cookieService.get('UNIFONIC_LOCALE') as UniLanguage;
  }

  setLanguageInLocalStorage(language): void {
    localStorage.setItem('lang', language);
  }

  setLanguage(language: UniLanguage = UniLanguage.en): void {
    this.translate.use(language);
    this.setLanguageInLocalStorage(language);
    this.uniLocalizationService.setDOMLanguage(language);
  }

  getLanguageFromLocalStorage(): UniLanguage {
    return localStorage.getItem('lang') as UniLanguage;
  }

  getLanguage(): UniLanguage {
    const browserLanguage: string = this.translate.getBrowserLang();
    const language: UniLanguage = includes(this.languages, browserLanguage)
      ? browserLanguage as UniLanguage
      : UniLanguage.en;

    return language;
  }

  getTranslationForLanguage(key: string, language: UniLanguage = UniLanguage.en): string | null {
    if (!this.translate.translations || !this.translate.translations[language]) {
      return null;
    }
    return this.translate.getParsedResult(this.translate.translations[language], key);
  }

  languageReady(language: UniLanguage = UniLanguage.en): Observable<boolean> {
    if (this.translate?.translations?.[language]) {
      return of(true);
    } else {
      return this.translate.onLangChange
        .pipe(
          map(res => !!res)
        );
    }
  }
}
