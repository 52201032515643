<div class="d-flex justify-content-between align-items-center">
  <uni-label [displayInline]="true">{{ label | translate }}</uni-label>
  <uni-button (click)="addVariable()" [fitContent]="true"
              [style]="'secondary'">{{ 'label.addHeader' | translate }}</uni-button>
</div>

<form [formGroup]="form">
  <ng-container formArrayName="variables">
    <div *ngFor="let vari of variables.controls; let control=index" class="variable">
      <ng-container [formGroupName]="control">
        <uni-form-field (cdkDropListDropped)="onDrop($event, vari.controls.key)" [margin]="false" cdkDropList>
          <uni-label [required]="true">
            {{ 'key' | translate }}
          </uni-label>
          <input formControlName="key" uniInput>
        </uni-form-field>
        <uni-form-field (cdkDropListDropped)="onDrop($event, vari.controls.value)" [margin]="false" cdkDropList>
          <uni-label [required]="true">
            {{ 'value' | translate }}
          </uni-label>
          <input formControlName="value" uniInput>
        </uni-form-field>
        <uni-icon (click)="removeVariable(control)" [name]="'fa fa-times'"></uni-icon>
      </ng-container>
    </div>
  </ng-container>
</form>
