import { concat, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { uniSnackbarActions, ValidationUtils } from '@unifonic/common';

import { WebhooksRepository } from '../shared/webhooks.repository';
import * as WebhooksActions from './webhooks.actions';
import { Router } from '@angular/router';

@Injectable()
export class WebhooksEffects {
  @Effect() getWebhooks$ = this.actions$
    .pipe(
      ofType<WebhooksActions.GetWebhooks>(WebhooksActions.GET_WEBHOOKS),
      mergeMap(({params}) => this.webhooksRepository
        .getWebhooks(params)
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.GetWebhooksSuccess(res.body.content)),
            of(new WebhooksActions.SetWebhooksAmount(res.body.totalElements)),
          )),
          catchError(() => concat(
            of(new WebhooksActions.GetWebhooksSuccess([])),
            of(new WebhooksActions.SetWebhooksAmount(0)),
          )),
        ),
      ),
    );
  @Effect() getProducts$ = this.actions$
    .pipe(
      ofType<WebhooksActions.GetProducts>(WebhooksActions.GET_PRODUCTS),
      mergeMap(() => this.webhooksRepository
        .getProducts()
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.GetProductsSuccess(res.body)),
          )),
          catchError(() => concat(
            of(new uniSnackbarActions.NewSnackbar('error', 'errors.general')),
            of(this.router.navigate(['/webhook/list']))
          )),
        ),
      ),
    );
  @Effect() setEvents$ = this.actions$
    .pipe(
      ofType<WebhooksActions.GetEvents>(WebhooksActions.GET_EVENTS),
      mergeMap(() => this.webhooksRepository
        .getEvents()
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.GetEventsSuccess(res.body))
          )),
          catchError(() => concat(
            of(new uniSnackbarActions.NewSnackbar('error', 'errors.general')),
            of(this.router.navigate(['/webhook/list']))
          )),
        ),
      ),
    );
  @Effect() getWebhook$ = this.actions$
    .pipe(
      ofType<WebhooksActions.GetWebhook>(WebhooksActions.GET_WEBHOOK),
      mergeMap(({webhookId}) => this.webhooksRepository
        .getWebhook(webhookId)
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.GetWebhookSuccess(res.body))
          )),
          catchError(() => concat(
            of(new uniSnackbarActions.NewSnackbar('error', 'errors.general')),
            of(this.router.navigate(['/webhook/list']))
          )),
        ),
      ),
    );
  @Effect() postWebhook$ = this.actions$
    .pipe(
      ofType<WebhooksActions.PostWebhook>(WebhooksActions.POST_WEBHOOK),
      mergeMap(({accountId, payload}) => this.webhooksRepository
        .postWebhook(accountId, payload)
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.PostWebhookSuccess(res.body)),
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successChanges'))
          )),
          catchError((error) => {
            if (error?.error?.message) {
              return of(new uniSnackbarActions.NewSnackbar('error', error.error.message));
            } else {
              return of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)));
            }
          })),
      ),
    );
  @Effect({dispatch: false}) putWebhookSuccess$ = this.actions$
    .pipe(
      ofType<WebhooksActions.PutWebhookSuccess>(WebhooksActions.PUT_WEBHOOK_SUCCESS),
      tap(() => this.router.navigate(['/webhook/list']))
    );
  @Effect() putWebhook$ = this.actions$
    .pipe(
      ofType<WebhooksActions.PutWebhook>(WebhooksActions.PUT_WEBHOOK),
      mergeMap(({accountId, payload, webhookId}) => this.webhooksRepository
        .putWebhook(accountId, payload, webhookId)
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.PutWebhookSuccess()),
            of(new uniSnackbarActions.NewSnackbar('success', 'snackbar.successChanges'))
          )),
          catchError((error) => {
            if (error?.error?.message) {
              return of(new uniSnackbarActions.NewSnackbar('error', error.error.message));
            } else {
              return of(new uniSnackbarActions.NewSnackbar('error', ValidationUtils.getViolationError(error)));
            }
          }),
        ),
      ),
    );
  @Effect() deleteWebhook$ = this.actions$
    .pipe(
      ofType<WebhooksActions.DeleteWebhook>(WebhooksActions.DELETE_WEBHOOK),
      mergeMap(({webhookId}) => this.webhooksRepository
        .deleteWebhook(webhookId)
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.DeleteWebhookSuccess()),
            of(new WebhooksActions.GetWebhooks()))),
          catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', 'errors.general')))
        ),
      ),
    );
  @Effect() testWebhook$ = this.actions$
    .pipe(
      ofType<WebhooksActions.TestWebhook>(WebhooksActions.TEST_WEBHOOK),
      mergeMap(({accountId, eventId, identifierValue}) => this.webhooksRepository
        .testWebhook(accountId, eventId, identifierValue)
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.TestWebhookSuccess())
          )),
          catchError((error) => of(new uniSnackbarActions.NewSnackbar('error', 'errors.general')))
        ),
      ),
    );
    @Effect() getQueuedEventsEvents$ = this.actions$
    .pipe(
      ofType<WebhooksActions.GetQueuedEvents>(WebhooksActions.GET_QUEUED_EVENTS),
      mergeMap(({webhookId}) => this.webhooksRepository
        .getQueuedEvents(webhookId)
        .pipe(
          mergeMap(res => concat(
            of(new WebhooksActions.GetQueuedEventsSuccess(res.body))
          )),
          catchError(() => concat(
            of(new uniSnackbarActions.NewSnackbar('error', 'errors.general'))
          )),
        ),
      ),
    );

  constructor(
    private router: Router,
    private actions$: Actions,
    private webhooksRepository: WebhooksRepository,
  ) {
  }
}
