import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebhookTableComponent } from './components/webhook-table/webhook-table.component';
import { UniSharedModule } from '@unifonic/common';
import { WebhookRemoveModalComponent } from './components/webhook-remove-modal/webhook-remove-modal.component';
import { WebhookListComponent } from './containers/webhook-list/webhook-list.component';
import {PrettyPrintPipe} from '@webhooks/shared/pretty-print.pipe';

const components = [
  WebhookListComponent,
  WebhookTableComponent,
  WebhookRemoveModalComponent
];

@NgModule({
  declarations: [
    ...components,
    PrettyPrintPipe
  ],
  exports: [
    PrettyPrintPipe
  ],
  imports: [
    CommonModule,
    UniSharedModule
  ]
})
export class WebhooksListModule { }
