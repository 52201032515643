<uni-icon
  name="wallet"
  [isRightSpace]="true"
  [isInherit]="true"
></uni-icon>
<ng-container *ngIf="activePackages$ | async as packages">
  <uni-tooltip-cloud *ngIf="isNumber(packages?.units); else unitsContainer">
    <ng-container *ngTemplateOutlet="unitsContainer"></ng-container>

    <div tooltip>
      <div class="uni-top-bar-balance__value uni-top-bar-balance__value--dark">
        {{ packages?.units }}
      </div>
    </div>
  </uni-tooltip-cloud>

  <ng-template #unitsContainer>
    <div>
      <div
        class="uni-top-bar-balance__label uni-top-bar-balance__label--margin"
      >
        {{ 'navigation.units' | translate }}
      </div>
      <div class="uni-top-bar-balance__value">
        <ng-container *ngIf="isNumber(packages?.units); else loaderContainer">
          {{ units(packages) }}
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>

<uni-tooltip-cloud *ngIf="account$ | async as account">
  <div class="uni-top-bar-balance__label">
    {{ 'navigation.balance' | translate }}
  </div>
  <div class="uni-top-bar-balance__value">
    <ng-container *ngIf="!!account; else loaderContainer">
      {{ balance(account) }}
    </ng-container>
  </div>

  <div tooltip>
    <div class="uni-top-bar-balance__value uni-top-bar-balance__value--dark">
      <ng-container *ngIf="!!account; else loaderContainer">
        {{ balance(account, true) }}
      </ng-container>
    </div>
  </div>
</uni-tooltip-cloud>

<ng-template #loaderContainer>
  <uni-icon
    name="spinner fa-spin"
  ></uni-icon>
</ng-template>
