import { Component, Input } from '@angular/core';
import { UniTextWeight } from '../../../uni-text/shared/uni-text.model';

@Component({
  selector: 'uni-nav-item-name',
  templateUrl: './uni-nav-item-name.component.html',
  styleUrls: ['./uni-nav-item-name.component.scss']
})
export class UniNavItemNameComponent {
  @Input() caption = '';
  uniTextWeight = UniTextWeight;
}
