<uni-text-caption [weight]="uniTextWeight.regular">
  <ng-content></ng-content>
</uni-text-caption>

<ng-container
  *ngIf="caption"
>
  <br />
  <uni-text-caption
    [weight]="uniTextWeight.medium"
    [color]="'dark-light'"
  >
    {{ caption }}
  </uni-text-caption>
</ng-container>
