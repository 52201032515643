import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebhookRoutingModule } from './webhooks-routing.module';

import { WebhookFormModule } from './modules/webhook-form/webhook-form.module';
import { WebhooksListModule } from './modules/webhooks-list/webhooks-list.module';
import { WebhooksRepository } from './shared/webhooks.repository';
import { UniSharedModule } from '@unifonic/common';
import { StoreModule } from '@ngrx/store';
import { WebhooksEffects, webhooksReducer } from './store';
import { EffectsModule } from '@ngrx/effects';
import { WebhooksFacade } from './shared/webhooks.facade';
import { WebhookFormFactory } from './shared/webhooks-form.factory';  



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UniSharedModule,
    WebhookRoutingModule,
    WebhookFormModule,
    WebhooksListModule,
    StoreModule.forFeature('example', webhooksReducer),
    EffectsModule.forFeature([WebhooksEffects]),
  ],
  providers: [
    WebhooksRepository,
    WebhooksFacade,
    WebhookFormFactory
  ]
})
export class WebhooksModule { }
