import { HttpResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { User } from '../shared/auth.model';

export const SET_USER_DATA = '[Auth] SET_USER_DATA';
export const SET_USER_DATA_SUCCESS = '[Auth] SET_USER_DATA_SUCCESS';
export const CLEAR_USER_DATA= '[Auth] CLEAR_USER_DATA';

export class SetUserData implements Action {
  readonly type = SET_USER_DATA;
  constructor() { }
}

export class SetUserDataSuccess implements Action {
  readonly type = SET_USER_DATA_SUCCESS
  constructor(public response: User) { }
}

export class ClearUserData implements Action {
  readonly type = CLEAR_USER_DATA;
  constructor() { }
}

export type All =
  | SetUserData
  | SetUserDataSuccess
  | ClearUserData;
