import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { httpEncoder } from '@unifonic/common';

import { Product, Webhook, WebhookEvent, WebhookQueuedEvents, WebhooksParams, WebhooksResponse } from './webhooks.model';
import { WEBHOOKS_KEY } from '@webhooks/store/webhooks.state';
import { AuthFacade } from '@auth/shared/auth.facade';
import { mergeMap } from 'rxjs/operators';
import { SettingsService } from '@shared/settings.service';

@Injectable()
export class WebhooksRepository {

  constructor(
    private http: HttpClient,
    private authFacade: AuthFacade,
    private settingsService: SettingsService
  ) {
  }

  getWebhooks(queryParams?: WebhooksParams): Observable<HttpResponse<WebhooksResponse>> {
    return this.authFacade.userData$.pipe(
      mergeMap(data => this.http.get<WebhooksResponse>(`${this.settingsService.apiUrl}/api/v1/accounts/${data.accountId}/webhooks`, {
          params: httpEncoder(queryParams),
          observe: 'response',
          headers: {
            queryName: 'getWebhooks',
            queryGroups: [WEBHOOKS_KEY],
          }
        }
      ))
    );
  }

  getProducts(): Observable<HttpResponse<Product[]>> {
    return this.http.get<Product[]>(`${this.settingsService.apiUrl}/api/v1/webhook/product`, {
        observe: 'response',
        headers: {
          queryName: 'getProducts',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

  getEvents(): Observable<HttpResponse<WebhookEvent[]>> {
    return this.http.get<WebhookEvent[]>(`${this.settingsService.apiUrl}/api/v1/webhook/event`, {
        observe: 'response',
        headers: {
          queryName: 'getProducts',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

  getWebhook(webhookId?: string): Observable<HttpResponse<Webhook>> {
    return this.http.get<Webhook>(`${this.settingsService.apiUrl}/api/v1/webhooks/${webhookId}`, {
        observe: 'response',
        headers: {
          queryName: 'getWebhook',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

  getQueuedEvents(webhookId: string): Observable<HttpResponse<WebhookQueuedEvents>> {
    return this.http.get<WebhookQueuedEvents>(`${this.settingsService.apiUrl}/api/v1/webhooks/${webhookId}/queuedEvents`, {
        observe: 'response',
        headers: {
          queryName: 'getQueuedEvents',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

  postWebhook(accountId: string, webhook: Webhook): Observable<HttpResponse<Webhook>> {
    return this.http.post<Webhook>(`${this.settingsService.apiUrl}/api/v1/accounts/${accountId}/webhooks`, webhook, {
        observe: 'response',
        headers: {
          queryName: 'postWebhook',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

  testWebhook(accountId: string, webhookId: string, identifierValue?: string): Observable<HttpResponse<null>> {
    return this.http.post<null>(`${this.settingsService.apiUrl}/api/v1/webhook/${accountId}/test/${webhookId}`, {identifierValue}, {
        observe: 'response',
        headers: {
          queryName: 'testWebhook',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

  putWebhook(accountId: string, webhook: Webhook, webhookId: string): Observable<HttpResponse<Webhook>> {
    return this.http.patch<Webhook>(`${this.settingsService.apiUrl}/api/v1/accounts/${accountId}/webhooks/${webhookId}`, webhook, {
        observe: 'response',
        headers: {
          queryName: 'putWebhook',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

  deleteWebhook(webhookId: string): Observable<HttpResponse<Webhook>> {
    return this.http.delete<Webhook>(`${this.settingsService.apiUrl}/api/v1/webhooks/${webhookId}`, {
        observe: 'response',
        headers: {
          queryName: 'deleteWebhook',
          queryGroups: [WEBHOOKS_KEY],
        }
      }
    );
  }

}
