<uni-nav>
  <uni-nav-category  *ngIf="navigationList" categoryName="sidebar.webhook.categoryName">
    <a *ngFor="let navigation of navigationList" routerLink="{{navigation.path}}">
      <uni-nav-item routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [isSidebar]="true">
        <uni-nav-item-icon>
          <uni-icon name="{{navigation.icon}}"></uni-icon>
        </uni-nav-item-icon>
        <uni-nav-item-name>
          {{ navigation.name | translate }}
        </uni-nav-item-name>
      </uni-nav-item>
    </a>
  </uni-nav-category>
</uni-nav>
