import { difference, get, has, isEmpty } from 'lodash';

import { Injectable, Optional, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as authActions from '../store/uni-auth.actions';
import {
  selectFeatureFlags, selectGetFeatureFlagsQuery, selectGetGlobalSettingsQuery, selectGetUserMeQuery,
  selectGlobalSettings, selectUserMe, selectAccount
} from '../store/uni-auth.selectors';
import { UniAuthState } from '../store/uni-auth.state';
import { ImpersonationUser, Permission, SystemSettings, User, UserMe, UserRole, AdditionalGrants } from './uni-auth.model';
import { FeatureFlags } from './uni-feature-flags.model';
import { BehaviorSubject } from 'rxjs';
import { isQueryInProgress$ } from '../../../store';
import { UniAuthService } from './uni-auth.service';
import { SettingsService } from '../../../shared/settings/settings.service';
import { Environment } from '../../../utils/environment.utils';

@Injectable({ providedIn: 'root' })
export class UniAuthFacade {
  account$ = this.store.pipe(select(selectAccount));
  userMe$ = this.store.pipe(select(selectUserMe));
  userMeQuery$ = this.store.pipe(select(selectGetUserMeQuery));
  globalSettings$ = this.store.pipe(select(selectGlobalSettings));
  globalSettingsQuery$ = this.store.pipe(select(selectGetGlobalSettingsQuery));
  featureFlags$ = this.store.pipe(select(selectFeatureFlags));
  featureFlagsQuery$ = this.store.pipe(select(selectGetFeatureFlagsQuery));
  systemSettings: SystemSettings;
  userMe: UserMe;
  user: User;

  constructor(
    private store: Store<{ auth: UniAuthState }>,
    private uniAuthService: UniAuthService,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) { }

  isLoading$ = new BehaviorSubject(isQueryInProgress$([
    this.featureFlagsQuery$,
    this.globalSettingsQuery$,
    this.userMeQuery$,
  ]));

  setData(userMe: UserMe) {
    const isCorrectUser = this.uniAuthService.isCorrectUser(userMe);
    const clientId = this.settingsService.getValue('clientId') || get(this.environment, 'clientId');

    this.uniAuthService.saveUserMe(userMe);

    this.userMe = userMe;
    this.user = this.userMe.user;
    this.systemSettings = this.userMe.systemSettings;

    if (!isCorrectUser) {
      this.uniAuthService.clearUserData();

      window.location.href = [
        `${Environment.getMainHost()}/oauth/v2/auth?response_type=code`,
        `&client_id=${clientId}`,
        `&redirect_uri=${window.location.href.split(/[?#]/)[0]}`
      ].join('');
    }
  }

  setFeatureFlags(data: FeatureFlags): void {
    this.store.dispatch(new authActions.SetFeatureFlagsSuccess(data));
  }

  setUserMe(isLoader = true): void {
    this.store.dispatch(new authActions.SetUserMe(isLoader));
  }

  setGlobalSettings(): void {
    this.store.dispatch(new authActions.SetGlobalSettings());
  }

  hasPermissionPath(path: string): boolean {
    return has(this.user, `permissions.${path}`);
  }

  getApplicationPermissions(path: string) {
    const permissions = get(this.user, 'permissions');
    return permissions[path];
  }

  getPermissions(path: string): Permission[] {
    return get(this.user, `permissions.${path}`) || [];
  }

  hasPermission(path: string, permission: Permission): boolean {
    return this.getPermissions(path).includes(permission);
  }

  hasSomePermission(path: string, permissions: Permission[], applicationPath?: string): boolean {
    if (applicationPath) {
      const applicationPermissions = this.getApplicationPermissions(applicationPath);
      const permissionList = get(applicationPermissions, path) || [];

      return !applicationPermissions
        ? false
        : permissionList.some(permission => permissions.includes(permission));
    }
    return this.getPermissions(path)
      .some(permission => permissions.includes(permission));
  }

  hasAllPermission(path: string, permissions: Permission[]): boolean {
    return difference(permissions, this.getPermissions(path)).length === 0;
  }

  hasAllRoles(roles: UserRole[]): boolean {
    return difference(roles, this.user.roles).length === 0;
  }

  hasSomeRoles(roles: UserRole[]): boolean {
    return this.user.roles.some(role => roles.includes(role));
  }

  hasUnifonicAdminRole(): boolean {
    return this.hasSomeRoles([UserRole.admin]);
  }

  hasAccountAdminRole(): boolean {
    return this.hasSomeRoles([UserRole.accountAdmin]);
  }

  hasAccountManagerRole(): boolean {
    return this.hasSomeRoles([UserRole.accountManager]);
  }

  hasParentAccount(): boolean {
    return !!get(this.user, 'account.parentAccount');
  }

  hasAccountUserRole(): boolean {
    return this.hasSomeRoles([UserRole.accountUser]);
  }

  hasUserRole(): boolean {
    return this.hasSomeRoles([UserRole.user]);
  }

  isAdminLevel(): boolean {
    return get(this.user, 'isAdminLevel');
  }

  isImpresonationSession(): boolean {
    return !isEmpty(get(this.userMe, 'impersonationSession'));
  }

  isBalance(): boolean {
    return has(this.user, 'account.balance');
  }

  isWhiteLabel(): boolean {
    return this.user.account?.isWhiteLabel || false;
  }

  getImpersonatee(): ImpersonationUser {
    return get(this.userMe, 'impersonationSession.impersonatee');
  }

  getImpersonator(): ImpersonationUser {
    return get(this.userMe, 'impersonationSession.impersonator');
  }

  hasSubAccount(): boolean {
    return this.user?.account?.userCount > 1;
  }

  getUserDataByKey(key: string): string {
    return get(this.user, key);
  }

  setUser(user: Partial<User>): void {
    Object.assign(this.userMe.user, user);

    this.setData(this.userMe);
    this.store.dispatch(new authActions.SetUserMeSuccess(this.userMe));
  }

  hasAdditionalGrants(permission: AdditionalGrants): boolean {
    return this.getAdditionalGrants(permission) && this.getAdditionalGrants(permission).includes(permission);
  }

  private getAdditionalGrants(path: string) {
    return get(this.user, 'additionalGrants');
  }
}
