<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-section-header>
    <uni-text-caption>
      {{ 'webhook.delete.action' | translate }}
    </uni-text-caption>
  </uni-section-header>
  <uni-modal-content>
    <span [innerHTML]="'webhook.delete.description' | translate"></span>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button [style]="'secondary'" (click)="hideModal()">
      {{ 'webhook.delete.cancel' | translate }}
    </uni-button>
    <uni-button (click)="onSubmit()">
      {{ 'webhook.delete.confirm' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
