import { distinctUntilChanged, filter } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { WebhooksFacade } from './webhooks.facade';
import { Webhook } from './webhooks.model';

@Injectable()
export class WebhookFormFactory {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private webhooksFacade: WebhooksFacade,
  ) {
    this.form = this.build();
  }

  build(): FormGroup {
    return this.formBuilder.group({
      endpointUrl: [null, [c => Validators.pattern('(https://.*):?(\\d*)\\/?(.+)')(c) ? {invalidUrl: true} : null,
        (control: AbstractControl) =>
          control.value && (control.parent.get('webhookEndpointUrlList').value || [])
            .some(x => control.value === x) ? {duplicatedUrl: true} : null]],
      name: [null, Validators.required],
      status: [false, Validators.required],
      description: [null, Validators.maxLength(256)],
      productEvents: new FormArray([]),
      webhookEndpointUrlList: new FormControl(),
    });
  }

  addEvent(): void {
    const productEvents = this.form.get('productEvents') as FormArray;
    const eventGroup = this.formBuilder.group({
      productId: ['', Validators.required],
      eventId: ['', Validators.required],
      identifierKey: [],
      identifierValue: [],
      excludePayloadAttributes: [null],
      includePayloadAttributes: [null],
      batching: [false],
      customHeaders: [null,
        control => control.value && Object.entries(control.value).some(([key, value]) => !key || !value)
          ? {invalid: true} : null
      ]
    });
    eventGroup.controls.productId.valueChanges.pipe(distinctUntilChanged()).subscribe(() => {
      [eventGroup.controls.identifierKey, eventGroup.controls.identifierValue].forEach(c => c.setValue(undefined));
    });
    productEvents.push(eventGroup);
  }

  removeEvent(index: number): void {
    (this.form.controls.productEvents as FormArray).removeAt(index);
  }

  addBatchingConfig(index: number, batching = true): void {
    const productEvents = this.form.get('productEvents') as FormArray;
    const batchingConfig = this.formBuilder.group({
      batchLimit: [null, Validators.required],
      batchSize: [null, Validators.required]
    });
    (productEvents.at(index) as FormGroup).addControl('batchingConfig', batchingConfig);
    (productEvents.at(index) as FormGroup).get('batching').setValue(batching, {emitEvent: false});
  }

  removeBatchingConfig(index: number): void {
    const productEvents = this.form.get('productEvents') as FormArray;
    (productEvents.at(index) as FormGroup).removeControl('batchingConfig');
  }

  fetchValues(): void {
    this.webhooksFacade.webhook$
      .pipe(filter(data => !!data))
      .subscribe(data => this.setValues(data));
  }

  setValues(data: Webhook, form: FormGroup = this.form): void {
    (this.form.controls.productEvents as FormArray).clear();
    data.productEvents?.forEach((productEvent, index) => {
      if (productEvent.identifierValue === 'NILL') {
        productEvent.identifierValue = undefined;
      }
      this.addEvent();
      if (productEvent.batchingConfig) {
        this.addBatchingConfig(index);
      }
    });

    form.patchValue(data);
  }
}
