<uni-modal-confirmation
  [isActive]="isTroubleshootModalActive$ | async"
  [title]="'troubleshooting.modal.toggleConfirm.title' | translate"
  [confirmLabel]="confirmLabel | translate"
  [cancelLabel]="'action.cancel' | translate"
  (close)="closeTroubleshootModal()"
  (confirm)="confirmTroubleshootMode()"
>
  <uni-section [isFirst]="true">
    <uni-section-header>
      <uni-text-header [level]="'300'" [color]="'primary'" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.header' | translate }}
      </uni-text-header>
    </uni-section-header>
  </uni-section>

  <uni-section>
    <uni-section-header [isBottomSpace]="false">
      <uni-text-header [color]="'primary'" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.sectionHeader1' | translate }}
      </uni-text-header>
    </uni-section-header>

    <uni-text-body [color]="'secondary'" [isUppercase]="false">
      {{ 'troubleshooting.modal.toggleConfirm.sectionDescription1' | translate }}
    </uni-text-body>
  </uni-section>

  <uni-section>
    <uni-section-header [isBottomSpace]="false">
      <uni-text-header [color]="'primary'" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.sectionHeader2' | translate }}
      </uni-text-header>
    </uni-section-header>

    <uni-text-body [color]="'secondary'" [isUppercase]="false">
      {{ 'troubleshooting.modal.toggleConfirm.sectionDescription2' | translate }}
    </uni-text-body>
  </uni-section>

  <uni-section [isLast]="true">
    <uni-section-header [isBottomSpace]="false">
      <uni-text-header [color]="'primary'" [isUppercase]="false">
        {{ 'troubleshooting.modal.toggleConfirm.sectionHeader3' | translate }}
      </uni-text-header>
    </uni-section-header>

    <uni-text-body [color]="'secondary'" [isUppercase]="false">
      {{ 'troubleshooting.modal.toggleConfirm.sectionDescription3' | translate }}
    </uni-text-body>
  </uni-section>
</uni-modal-confirmation>
