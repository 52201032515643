import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Settings, SettingsService, UniLayoutFacade } from '@unifonic/common';
import { AuthService } from './auth.service';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthFacade } from './auth.facade';

@Injectable()
export class AuthGuard implements CanActivate {
  settings: Settings = this.settingsService.settings;

  constructor(
    private authFacade: AuthFacade,
    private uniLayoutFacade: UniLayoutFacade,
    private authService: AuthService,
    private settingsService: SettingsService,
  ) {}

  getUserData(): Observable<any> {
    return this.authFacade.userData$.pipe(
      tap(data => {
        if (!data) {
          this.authFacade.setUserData();
        }
      }),
      filter(data => !!data),
      tap(data => this.authService.saveUserData(data)),
      tap(() => this.uniLayoutFacade.setIsLoader(false)),
      take(1),
    );
  }

  canActivate(): Observable<boolean> {
    this.uniLayoutFacade.setIsLoader(true);

    return this.getUserData()
      .pipe(
        switchMap(() => of(true)),
        catchError(() => of(false)),
      );
  }
}
