import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { SettingsService } from '../../../shared';
import { Environment } from '../../../utils';
import { TwoFactorCode, TwoFactorToken, TwoFactorTokenResponse } from './uni-2fa.model';

@Injectable()
export class Uni2faRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) {}


  getUserMeApiUrl(): string {
    const ucUrl = this.settingsService.settings?.ucUrl;

    return ucUrl
      ? `${ucUrl}/api/v2`
      : `${Environment.getMainHost()}/app/api/v2`;
  }

  postCode(payload: TwoFactorCode): Observable<HttpResponse<null>> {
    return this.http.put<null>(`${this.getUserMeApiUrl()}/otp/send-code`, payload, {
      observe: 'response',
      withCredentials: true
    });
  }

  getToken(payload: TwoFactorToken): Observable<HttpResponse<TwoFactorTokenResponse>> {
    return this.http.put<TwoFactorTokenResponse>(`${this.getUserMeApiUrl()}/otp/generate-token`, payload, {
      observe: 'response',
      withCredentials: true
    });
  }
}
