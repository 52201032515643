import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from './auth.model';
import { AUTH_KEY } from '@auth/store';
import { SettingsService } from '@shared/settings.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRepository {
  constructor(
    private http: HttpClient,
    private settingsService: SettingsService
  ) {
  }

  getUserData(): Observable<User> {
    return this.http.get<User>(`${this.settingsService.apiUrl}/api/v1/auth/get-user-data`, {
      headers: {
        queryName: 'getUserData',
        queryGroups: [AUTH_KEY]
      }
    });
  }
}
