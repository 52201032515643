import * as AuthActions from './auth.actions';
import {
  initialState,
  AuthState
} from './auth.state';

export function authReducer(
  state = initialState,
  action: AuthActions.All,
): AuthState {
  switch (action.type) {
    case AuthActions.SET_USER_DATA_SUCCESS: {
      return {
        ...state,
        userData: action.response,
      };
    }
    case AuthActions.CLEAR_USER_DATA: {
      return {
        ...state,
        userData: undefined,
      };
    }
    default: {
      return {...state};
    }
  }
}
