import { Injectable } from '@angular/core';
import { User } from './auth.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  getAccountId(): string {
    return localStorage.getItem('accountId');
  }

  saveUserData(userData: User): void {
    localStorage.setItem('user_data', JSON.stringify(userData));
    localStorage.setItem('accountId', userData.accountId);
  }
}
