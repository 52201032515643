<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-section-header>
    <uni-text-caption>
      {{ title }}
    </uni-text-caption>
  </uni-section-header>
  <uni-modal-content>
    <ng-container *ngIf="description; else renderContent">
      {{ description }}
    </ng-container>
    <ng-template #renderContent>
      <ng-content></ng-content>
    </ng-template>
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button
      [style]="'secondary'"
      (click)="hideModal()"
    >
      {{ cancelLabel }}
    </uni-button>
    <uni-button (click)="onConfirm()">
      {{ confirmLabel }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
