import { Observable } from 'rxjs';
import { get } from 'lodash';

import { Injectable, Optional, Inject } from '@angular/core';
import {
  Account,
  Accounts,
  GetAccountsParams,
  PutTroubleshootingModePayload,
  SubAccount
} from '../model/accounts.model';
import { SettingsService } from '../../settings/settings.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Environment } from '../../../utils';
import { ACCOUNTS_KEY } from '../store/uni-data.state';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AccountsRepository {
  accountsUrl = `${Environment.getMainHost()}/api/v2`;
  accountsV3Url = `${Environment.getMainHost()}/app/api/v3/accounts`;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    @Optional() @Inject('environment') public environment,
  ) { }

  getAccounts(params: GetAccountsParams = { includeAccount: true }): Observable<Account[]> {
    return this.http.get<Account[]>(`${this.accountsUrl}/accounts/list`, {
      params: params as HttpParams,
      withCredentials: true
    });
  }

  getAllAccounts(params: Params = {}): Observable<Account[]> {
    return this.http.get<Account[]>(`${this.accountsUrl}/accounts`, {
      params,
      withCredentials: true
    });
  }

  getSubAccounts(): Observable<SubAccount[]> {
    const apiUrl = this.settingsService.ccApiUrl(false) || get(this.environment, 'apiUrl');

    return this.http.get<SubAccount[]>(`${apiUrl}/sub-accounts`, {});
  }

  getAccountsChoice(): Observable<Accounts> {
    const apiUrl = this.settingsService.ccApiUrl(false) || get(this.environment, 'apiUrl');

    return this.http.get<Accounts>(`${apiUrl}/accounts/list/choice`, {
      headers: {
        queryName: 'getAccountsChoice',
        queryGroups: [ACCOUNTS_KEY]
      }
    });
  }

  putAccountTroubleshootingMode(accountId: string, payload: PutTroubleshootingModePayload): Observable<void> {
    return this.http.put<void>(`${ this.accountsV3Url }/${ accountId }/troubleshooting-mode`, payload, {
      headers: {
        queryName: 'putAccountTroubleshootingMode',
        queryGroups: [ACCOUNTS_KEY],
      },
    });
  }
}
