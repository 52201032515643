import { Product, Webhook, WebhookEvent, WebhookQueuedEvents } from '../shared/webhooks.model';

export const WEBHOOKS_KEY = 'webhooks';
export interface WebhooksState {
  webhooks?: Webhook[];
  webhooksAmount?: number;
  webhook?: Webhook;
  products?: Product[];
  events?: WebhookEvent[];
  queuedEvents?: WebhookQueuedEvents;
}

export const initialState: WebhooksState = {
  webhooks: [],
  webhooksAmount: 0
};
