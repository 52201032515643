import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';

import { AuthRepository } from '../shared/auth.repository';
import * as AuthActions from './auth.actions';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authRepository: AuthRepository,
  ) { }

  @Effect() setUserData$ = this.actions$
    .pipe(
      ofType<AuthActions.SetUserData>(AuthActions.SET_USER_DATA),
      mergeMap(() => this.authRepository
        .getUserData()
        .pipe(
          mergeMap(userData => of(new AuthActions.SetUserDataSuccess(userData))),
        )
      )
    );
}
