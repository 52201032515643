import { Subscription } from 'rxjs';

import { Component, OnInit } from '@angular/core';

import { AutoUnsubscribe } from '../../../../utils';
import { UniNavFacade } from '../../../uni-nav/shared/uni-nav.facade';

@Component({
  selector: 'uni-top-bar-menu',
  templateUrl: './uni-top-bar-menu.component.html',
  styleUrls: ['./uni-top-bar-menu.component.scss']
})
export class UniTopBarMenuComponent extends AutoUnsubscribe implements OnInit {
  isProfileNavOpen = false;

  constructor(private uniNavFacade: UniNavFacade) {
    super();
  }

  ngOnInit() {
    this.subs.sink = this.selectprofileNavToggle();
  }

  selectprofileNavToggle(): Subscription {
    return this.uniNavFacade.isProfileNavOpen$.subscribe(state => {
      this.isProfileNavOpen = state;
    });
  }
}
