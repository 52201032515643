<uni-modal
  isCloseIcon="true"
  [isActive]="isActive"
  (isActiveChange)="hideModal()"
>
  <uni-modal-content>
    {{ 'webhook.test.description' | translate }}
  </uni-modal-content>
  <uni-modal-footer>
    <uni-button (click)="onSubmit()">
      {{ 'webhook.test.ok' | translate }}
    </uni-button>
  </uni-modal-footer>
</uni-modal>
