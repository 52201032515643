import * as QueryActions from '../../../store/query/query.actions';
import { queryReducer } from '../../../store/query/query.reducers';

import * as UniDataActions from './uni-data.actions';
import {
  GET_ACCOUNTS_QUERY,
  GET_SUB_ACCOUNTS_QUERY,
  GET_OPERATORS_QUERY,
  GET_SENDER_NAMES_QUERY,
  GET_PROVIDERS_QUERY,
  initialState,
  UniDataState,
  GET_All_ACCOUNTS_QUERY
} from './uni-data.state';

const queryState = (state: UniDataState, action: UniDataActions.All | QueryActions.All): UniDataState => ({
  ...state,
  ...queryReducer<UniDataState>([
    GET_OPERATORS_QUERY,
    GET_ACCOUNTS_QUERY,
    GET_All_ACCOUNTS_QUERY,
    GET_SUB_ACCOUNTS_QUERY,
    GET_SENDER_NAMES_QUERY,
    GET_PROVIDERS_QUERY
  ], action as QueryActions.All),
});

export function reducer(
  state = initialState,
  action: UniDataActions.All,
): UniDataState {
  switch (action.type) {
    case UniDataActions.SET_OPERATORS_SUCCESS: {
      return {
        ...state,
        operators: action.response,
      };
    }
    case UniDataActions.SET_PROVIDERS_SUCCESS: {
      return {
        ...state,
        providers: action.response,
      };
    }
    case UniDataActions.SET_ACCOUNTS_SUCCESS:
    case UniDataActions.SET_All_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        accounts: action.response,
      };
    }
    case UniDataActions.SET_SUB_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        subAccounts: action.response,
      };
    }
    case UniDataActions.SET_SENDER_NAMES_SUCCESS: {
      return {
        ...state,
        senderNames: action.response,
      };
    }
    case UniDataActions.SET_ACCOUNTS_CHOICE_SUCCESS: {
      return {
        ...state,
        accountsChoice: action.response,
      };
    }
    case UniDataActions.SET_PROVIDERS_CHOICE_SUCCESS: {
      return {
        ...state,
        providersChoice: action.response,
      };
    }
    case UniDataActions.SET_OPERATORS_CHOICE_SUCCESS: {
      return {
        ...state,
        operatorsChoice: action.response,
      };
    }
    case UniDataActions.SET_SENDERS_CHOICE_SUCCESS: {
      return {
        ...state,
        sendersChoice: action.response,
      };
    }
    case UniDataActions.CLEAR_SENDER_NAMES: {
      return {
        ...state,
        senderNames: undefined,
      };
    }
    case UniDataActions.SET_ACTIVE_PACKAGES_SUCCESS: {
      return {
        ...state,
        activePackages: action.response,
      };
    }
    default: {
      return queryState(state, action);
    }
  }
}
