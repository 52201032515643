import { InjectionToken } from '@angular/core';
import { environment } from '@env/environment';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import * as authStore from '../+auth/store';
import * as webhookStore from '@webhooks/store';

//  State
// ----------------------------------------
export interface State {
  auth: authStore.AuthState;
  webhooks: webhookStore.WebhooksState;
}

export const initialState: State = {
  auth: authStore.initialState,
  webhooks: webhookStore.initialState
};

// Reducers
// -----------------------------------------

export const reducers = {
  auth: authStore.authReducer,
  webhooks: webhookStore.webhooksReducer
};

//  Providers
// ----------------------------------------
export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('app.reducer');

export const getReducers = () => reducers;

export const reducerProvider = {
  provide: REDUCER_TOKEN,
  useFactory: getReducers
};

//  Modules
// ----------------------------------------
export const storeModule = [
  StoreModule.forRoot(REDUCER_TOKEN, { initialState })
];

export const effectsModule = [
  EffectsModule.forRoot([
    authStore.AuthEffects,
    webhookStore.WebhooksEffects,
  ])
];

export const storeDevtoolsModule = !environment.production
  ? [StoreDevtoolsModule.instrument({ maxAge: 50 })]
  : [];
