import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { UniCommonModule } from '@unifonic/common';

const modules = [
  CommonModule,
  UniCommonModule,
  TranslateModule,
  RouterModule,
];

@NgModule({
  imports: [...modules],
  exports: [...modules],
})
export class SharedModule { }
