import * as _moment from 'moment';
import * as _momentTimezone from 'moment-timezone';

import { HttpParameterCodec } from '@angular/common/http';

export const moment = _moment;
export const momentTimezone = _momentTimezone;

export namespace DateUtils {
  export enum Format {
    default = 'DD.MM.YYYY',
    defaultDay = 'DD.MM',
    defaultDateTime = 'DD.MM.YYYY, HH:mm',
    meridiemDateTime = 'DD.MM.YYYY, h:mm A',
    apiDefault = 'YYYY-MM-DD',
    apiMeridiemDateTime = 'YYYY-MM-DDh:mma',
    exact = 'DD.MM.YYYY HH:mm',
    hours = 'hh:mm',
    hoursDefault = 'H:mm',
    apiMonth = 'YYYY-MM',
    defaultMonth = 'MM.YYYY',
    apiWeek = 'YYYY-W',
    defaultWeek = '(W)YYYY',
    fullMonth = 'MMMM YYYY',
    withSeconds = 'DD-MM-YYYY H:mm:ss',
    withMilliSeconds = 'DD-MM-YYYY H:mm:ss.SSS',
    isoStandardFull = 'YYYY-MM-DD H:mm:ss',
    isoStandardFullZero = 'YYYY-MM-DD HH:mm:ss',
    dateWithMonthName = 'DD MMM, YYYY'
  }

  export const datepickerFormat = {
    parse: {
      dateInput: Format.default,
    },
    display: {
      dateInput: Format.default,
      monthYearLabel: 'MMM.YYYY',
      dateA11yLabel: Format.default,
      monthYearA11yLabel: 'MMMM.YYYY',
    },
  };

  export const datepickerMonthFormat = {
    parse: {
      dateInput: Format.apiMonth,
    },
    display: {
      dateInput: Format.fullMonth,
      monthYearLabel: 'MMM.YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

  export const getDate = (date: moment.MomentInput, format: string = Format.apiDefault) => moment(date).format(format);

  export const compareDates = (a, b): boolean => {
    return a.unix() > b.unix();
  };

  export const endTimeIsBeforeStartTime = (start, end): boolean => {
    const startTime = moment(start, 'h:mma');
    const endTime = moment(end, 'h:mma');

    return endTime.isBefore(startTime);
  };

  export interface TimeFormatsAttributes {
    inputFormat?: string;
    outputFormat?: string;
  }
  export const convert12HoursTo24Hours = (time: string, format?: TimeFormatsAttributes): string => {
    const {inputFormat = 'h:mm A', outputFormat = 'HH:mm'} = format;
    return moment(time, [inputFormat]).format(outputFormat);
  };

  export const convert24HoursTo12Hours = (time: string, format?: TimeFormatsAttributes): string => {
    const {inputFormat = 'HH:mm', outputFormat = 'h:mm A'} = format;
    return moment(time, [inputFormat]).format(outputFormat);
  };

  export const get = (date, format = Format.default): string => {
    return moment(date, Format.default).format(format);
  };

  export const getCurrentDate = (timezone): _momentTimezone.Moment => {
    return momentTimezone.tz(moment(), timezone);
  };

  export const getTimeZoneObject = (...values): _momentTimezone.Moment => {
    return momentTimezone.tz.apply(null, values);
  };

  export const getFormattedDate = (date, timezone, format = DateUtils.Format.apiMeridiemDateTime): string => {
    return momentTimezone
      .tz(date, format, timezone)
      .format();
  };

  export const getTimezoneDate = (date, timezone, format = Format.default): string => {
    return momentTimezone(date)
      .tz(timezone)
      .format(format);
  };

  export const display = (date: string, format = Format.default, timezone?: string): string => {
    return timezone ? getTimezoneDate(date, timezone, format) : moment(date).format(format);
  };

  export const getStartOfDay = (date): string => {
    return moment(date, Format.defaultDateTime).startOf('day').format();
  };

  export const getTimezoneStartOfDay = (date, timezone, format = null): string => {
    return momentTimezone(date, Format.defaultDateTime)
      .startOf('day')
      .tz(timezone)
      .format(format);
  };

  export const getTimezoneStartOfMonth = (date, timezone): string => {
    return momentTimezone(date, Format.defaultDateTime)
      .startOf('month')
      .tz(timezone)
      .format();
  };

  export const getEndOfDay = (date): string => {
    return moment(date, Format.defaultDateTime).endOf('day').format();
  };

  export const subtractWeeksFromToday = (timezone, weeksToSubtract): _momentTimezone.Moment => {
    return momentTimezone.tz(moment().subtract(weeksToSubtract, 'weeks').endOf('week'), timezone);
  };

  export const getTimezoneEndOfDay = (date, timezone, format = null): string => {
    return momentTimezone(date, Format.defaultDateTime)
      .endOf('day')
      .tz(timezone)
      .format(format);
  };

  export class CustomEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
      return encodeURIComponent(key);
    }

    encodeValue(value: string): string {
      return encodeURIComponent(value);
    }

    decodeKey(key: string): string {
      return decodeURIComponent(key);
    }

    decodeValue(value: string): string {
      return decodeURIComponent(value);
    }
  }
}
