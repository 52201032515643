<div class="uni-top-bar__products-container">
  <uni-top-bar-products></uni-top-bar-products>
  <uni-top-bar-logo class="top-bar-logo" *ngIf="flagsFetched$ | async" [logoUrl]="logoUrl$ | async"></uni-top-bar-logo>
</div>

<div
  class="uni-top-bar__session-container"
  [class.is-impersonation-session]="isImpresonationSession"
>
  <uni-top-bar-impersonation *ngIf="isImpresonationSession"></uni-top-bar-impersonation>
  <ng-container *ngIf="isBalance">
    <uni-top-bar-separator class="uni-top-bar--hide-on-mobile"></uni-top-bar-separator>
    <uni-top-bar-balance class="uni-top-bar--hide-on-mobile"></uni-top-bar-balance>
    <uni-top-bar-topup *ngIf="!isAdminLevel" [class.is-impersonation-session]="isImpresonationSession"></uni-top-bar-topup>
  </ng-container>
  <uni-top-bar-separator class="uni-top-bar--hide-on-mobile"></uni-top-bar-separator>
  <uni-top-bar-timezone class="uni-top-bar--hide-on-mobile"></uni-top-bar-timezone>
  <span
    class="uni-top-bar__profile-container"
    (click)="onOpenProfileNav(!isProfileNavOpen)"
    (uniClickOutside)="onChange(false)"
  >
    <uni-top-bar-separator></uni-top-bar-separator>
    <uni-top-bar-avatar></uni-top-bar-avatar>
    <uni-top-bar-separator *ngIf="!(newDesignEnabled$ | async)"></uni-top-bar-separator>
    <uni-top-bar-menu></uni-top-bar-menu>
  </span>
</div>
<uni-products-content [isProductNavOpen]="isProductNavOpen"></uni-products-content>
