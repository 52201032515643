import { createSelector } from '@ngrx/store';
import { State } from 'src/app/store';
import { WebhooksState } from './webhooks.state';


export const selectWebhooksFeature = (state: State) => state.webhooks;

export const selectWebhooks = createSelector(
  selectWebhooksFeature,
  (state: WebhooksState) => state.webhooks,
);

export const selectProducts = createSelector(
  selectWebhooksFeature,
  (state: WebhooksState) => state.products,
);

export const selectEvents = createSelector(
  selectWebhooksFeature,
  (state: WebhooksState) => state.events,
);

export const selectQueuedEvents = createSelector(
  selectWebhooksFeature,
  (state: WebhooksState) => state.queuedEvents,
);

export const selectWebhooksAmount = createSelector(
  selectWebhooksFeature,
  (state: WebhooksState) => state.webhooksAmount,
);

export const selectWebhook = createSelector(
  selectWebhooksFeature,
  (state: WebhooksState) => state.webhook,
);