<uni-label>
    {{ 'label.startTime' | translate }}
</uni-label>
<uni-form-container>
  <uni-form-select
    [control]="timeControl"
    [data]="shownTimes"
    [placeholder]="'label.chooseTime' | translate"
    [form]="form"
  ></uni-form-select>
</uni-form-container>
