<uni-label class="mb-0">{{'webhook.payload.title' | translate}}</uni-label>
<p>{{'webhook.payload.selectedKeys' | translate}}</p>

<div class="row">
  <div class="col-md-6">
    <uni-label class="mb-0">{{'webhook.payload.originalPayload' | translate}}</uni-label>
    <p>{{'webhook.payload.originalPayloadDescription' | translate}}</p>
    <div class="content-box">
      <ng-container
        *ngTemplateOutlet="payloadTmp; context:{$implicit:payloadPairs,reserveSelectAllSpace:true}"></ng-container>
    </div>
  </div>
  <div class="col-md-6">
    <uni-label class="mb-0">{{'webhook.payload.modifiedPayload' | translate}}</uni-label>
    <p>{{'webhook.payload.modifiedPayloadDescription' | translate}}</p>
    <div class="content-box">
      <ng-container
        *ngTemplateOutlet="payloadTmp; context:{$implicit:payloadPairs,isEditable:true,showSelectAll:true}"></ng-container>
    </div>
  </div>
</div>

<ng-template #payloadTmp let-includeComma="includeComma" let-isArray="isArray" let-isEditable="isEditable"
             let-pairs let-reserveSelectAllSpace="reserveSelectAllSpace" let-showSelectAll="showSelectAll">
  <div *ngIf="reserveSelectAllSpace || showSelectAll" class="select-all-box">
    <ng-container *ngIf="showSelectAll">
      <div class="d-flex justify-content-between align-items-center">
        <uni-checkbox class="d-inline">
          <mat-checkbox (change)="handleSelectAll($event.checked)"
                        [checked]="isAllSelected" [indeterminate]="isSomeSelected">
            {{'webhook.payload.selectAll' | translate}}
          </mat-checkbox>
        </uni-checkbox>
        <uni-link (click)="toggleShowSelectedOnly()"
                  type="secondary">{{(!showSelectedOnly ? 'webhook.payload.showSelectedOnly' : 'webhook.payload.showAll') | translate}}</uni-link>
      </div>
      <uni-separator class="my-2"></uni-separator>
    </ng-container>
  </div>
  <ul class="payload">
    <li>{{isArray ? '&#91;' : '&#123;'}}</li>
    <ng-container *ngFor="let pair of pairs; last as isLast">
      <li *ngIf="(!isEditable && pair.type !== payloadEntryType.Extra) || (isEditable && !pair.hidden)"
          [class]="isEditable ? getPropClass(pair) : ''"
          [formGroup]="getFormGroup(pair)"
          class="property">
        <uni-checkbox *ngIf="isEditable" class="d-inline">
          <mat-checkbox *ngIf="!isObjectOrArray(pair); else objSelectedTmp" formControlName="selected"></mat-checkbox>
          <ng-template #objSelectedTmp>
            <mat-checkbox (change)="handleSelectAll($event.checked,pair.value)"
                          (indeterminateChange)="handleSomeSelected($event,pair)"
                          [indeterminate]="getIsSomeSelected(pair.value)"
                          formControlName="selected"></mat-checkbox>
          </ng-template>
        </uni-checkbox>
        <div class="key">
          <ng-container *ngIf="!isEditable; else editableKeyTmp">"{{pair.key}}"</ng-container>
          <ng-template #editableKeyTmp>
            <span #mockInput [innerText]="keyInput.value" class="input-mock"></span>
            "<input #keyInput [id]="pair.path" [style.width]="mockInput.offsetWidth+'px'" formControlName="key"/>"
          </ng-template>
        </div>
        <span class="equals">=</span>
        <div class="value">
          <ng-container
            *ngTemplateOutlet="valueTmp; context:{$implicit:pair,isEditable:isEditable,isLast:isLast}"></ng-container>
          <ng-container *ngIf="!isObjectOrArray(pair)">
            <ng-container *ngIf="!isLast; else addPairTmp">,</ng-container>
            <ng-template #addPairTmp>
              <uni-icon (click)="addPair(pairs)" *ngIf="isEditable" [isLeftSpace]="true" name="plus"></uni-icon>
            </ng-template>
          </ng-container>
        </div>
      </li>
    </ng-container>
    <li>{{isArray ? '&#93;' : '&#125;'}}{{includeComma ? ',' : ''}}</li>
  </ul>
</ng-template>

<ng-template #valueTmp let-isEditable="isEditable" let-isLast="isLast" let-pair>
  <ng-container [ngSwitch]="pair.type">
    <ng-container *ngSwitchCase="payloadEntryType.Array">
      <ng-container
        *ngTemplateOutlet="payloadTmp; context:{$implicit:pair.value,isEditable:isEditable,includeComma:!isLast,isArray:true}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="payloadEntryType.Object">
      <ng-container
        *ngTemplateOutlet="payloadTmp; context:{$implicit:pair.value,isEditable:isEditable,includeComma:!isLast}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="payloadEntryType.String">"{{pair.value}}"</ng-container>
    <ng-container *ngSwitchCase="payloadEntryType.Extra" [formGroup]="getFormGroup(pair)">
      <span #mockInput [innerText]="valueInput.value" class="input-mock"></span>
      "<input #valueInput [style.width]="mockInput.offsetWidth+'px'" formControlName="value"/>"
    </ng-container>
    <ng-container *ngSwitchDefault>{{pair.value}}</ng-container>
  </ng-container>
</ng-template>
