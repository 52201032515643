import { Component, OnInit } from '@angular/core';
import { AutoUnsubscribe, UniNewNavEnabledService, } from '@unifonic/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fs-app-logged',
  templateUrl: './app-logged.component.html',
  styleUrls: ['./app-logged.component.scss'],
})
export class AppLoggedComponent extends AutoUnsubscribe implements OnInit {
  isNewNav = false;
  isNewNavFetched = false;

  constructor(private uniNewNavEnabledService: UniNewNavEnabledService) {
    super();
  }

  ngOnInit(): void {
    this.subs.sink = this.checkFlags();
  }

  checkFlags(): Subscription {
    return this.uniNewNavEnabledService.isNewMenuEnabled$.subscribe((value) => {
      this.isNewNavFetched = true;
      this.isNewNav = value;
    });
  }
}
