import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { UniAuthFacade } from '../modules/uni-auth/shared/uni-auth.facade';
import { DateUtils } from '../utils';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  timezone = this.uniAuthFacade.user.timezone;

  constructor(private uniAuthFacade: UniAuthFacade) {
    super('en-GB');
  }

  transform(date: string, format?: DateUtils.Format): any {
    const value = DateUtils.display(date, format, this.timezone);
    return value === 'Invalid date'
      ? '-'
      : value;
  }
}
