import { values } from 'lodash';

export namespace Environment {
  export enum APP {
    CONV = 'conversation',
    VOICE = 'voice',
    CC = 'cc',
    UC = 'uc',
    PROD_CC = 'communication',
    AUDIENCE = 'customer-targeting-backend',
    ADMIN_LOGS = 'admin-logs-backend',
    FLOW_STUDIO_LOCAL = 'flow-studio',
    FLOW_STUDIO_PROD = 'flow',
    WEBHOOK = 'webhook',
    INTEGRATIONS = 'integrations',
    WEB = 'web',
    CDP = 'audience',
    CAMPAIGN = 'campaign',
    NOTICE = 'notice',
    PUSH = 'push',
    AUTHENTICATE = 'authenticate',
    ULINK = 'ulink',
    INSIGHTS = 'insights'
  }

  export enum DEV_URL {
    CC = '172.18.18.111',
    UC = '172.18.18.101',
    VOICE = '172.18.18.131',
    CONV = '172.20.0.18',
    PUSH = '172.21.0.21',
    AUDIENCE = '172.18.18.141',
    ADMIN_LOGS = '172.18.18.151',
    ULINK = '172.22.0.22',
    NOTICE = '172.19.0.21',
    AUTHENTICATE = '172.20.0.21',
  }

  export function getMainHost(prefix?: string): string {
    const { hostname, protocol } = window.location;

    if (values(DEV_URL).includes(hostname as any)) {
      return `${protocol}//${DEV_URL.UC}`;
    }

    const parts = hostname.split('.');

    if (values(APP).includes(parts[0].replace(/^local/, '') as any)) {
      parts.shift();
    }

    return !!prefix
      ? `${protocol}//${prefix}.${parts.join('.')}`
      : `${protocol}//${parts.join('.')}`;
  }

  export function getProduct(): string {
    const { hostname } = window.location;
    const parts = hostname.split('.');

    if (values(APP).includes(parts[0] as any)) {
      return parts[0];
    }

    return APP.UC;
  }
}
