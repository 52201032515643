import { Params } from '@angular/router';
import { Action } from '@ngrx/store';
import { Provider, Providers } from '../model/provider.model';
import { Account, SubAccount, Accounts, GetAccountsParams } from '../model/accounts.model';
import { Operator, Operators, OperatorsParams } from '../model/operator.model';
import { Senders, UniSenderName } from '../model/sender-name.model';
import { ActivePackages } from '../model';

export const SET_OPERATORS = '[Operators] SET_OPERATORS';
export const SET_OPERATORS_SUCCESS = '[Operators] SET_OPERATORS_SUCCESS';

export const SET_PROVIDERS = '[Providers] SET_PROVIDERSS';
export const SET_PROVIDERS_SUCCESS = '[Providers] SET_PROVIDERS_SUCCESS';

export const SET_ACCOUNTS = '[UniAccounts] SET_ACCOUNTS';
export const SET_ACCOUNTS_SUCCESS = '[UniAccounts] SET_ACCOUNTS_SUCCESS';

export const SET_SUB_ACCOUNTS = '[UniAccounts] SET_SUB_ACCOUNTS';
export const SET_SUB_ACCOUNTS_SUCCESS = '[UniAccounts] SET_SUB_ACCOUNTS_SUCCESS';

export const SET_SENDER_NAMES = '[UniSenderNames] SET_SENDER_NAMES';
export const SET_SENDER_NAMES_SUCCESS = '[UniSenderNames] SET_SENDER_NAMES_SUCCESS';

export const CLEAR_SENDER_NAMES = '[UniSenderNames] CLEAR_SENDER_NAMES';

export const SET_All_ACCOUNTS = '[UniAccounts] SET__All_ACCOUNTS';
export const SET_All_ACCOUNTS_SUCCESS = '[UniAccounts] SET_All_ACCOUNTS_SUCCESS';

export const SET_ACCOUNTS_CHOICE = '[UniAccounts] SET_ACCOUNTS_CHOICE';
export const SET_ACCOUNTS_CHOICE_SUCCESS = '[UniAccounts] SET_ACCOUNTS_CHOICE_SUCCESS';

export const PUT_ACCOUNT_TROUBLESHOOTING_MODE = '[UniAccounts] PUT_ACCOUNT_TROUBLESHOOTING_MODE';
export const PUT_ACCOUNT_TROUBLESHOOTING_MODE_SUCCESS = '[UniAccounts] PUT_ACCOUNT_TROUBLESHOOTING_MODE_SUCCESS';

export const SET_OPERATORS_CHOICE = '[Operators] SET_OPERATORS_CHOICE';
export const SET_OPERATORS_CHOICE_SUCCESS = '[Operators] SET_OPERATORS_CHOICE_SUCCESS';

export const SET_PROVIDERS_CHOICE = '[Providers] SET_PROVIDERS_CHOICE';
export const SET_PROVIDERS_CHOICE_SUCCESS = '[Providers] SET_PROVIDERS_CHOICE_SUCCESS';

export const SET_SENDERS_CHOICE = '[UniSenderNames] SET_SENDERS_CHOICE';
export const SET_SENDERS_CHOICE_SUCCESS = '[UniSenderNames] SET_SENDERS_SUCCESS_CHOICE';

export const SET_ACTIVE_PACKAGES = '[UniPackages] SET_ACTIVE_PACKAGES';
export const SET_ACTIVE_PACKAGES_SUCCESS = '[UniPackages] SET_ACTIVE_PACKAGES_SUCCESS';

export class SetOperators implements Action {
  readonly type = SET_OPERATORS;

  constructor() { }
}

export class SetOperatorsSuccess implements Action {
  readonly type = SET_OPERATORS_SUCCESS;

  constructor(public response: Operator[]) { }
}

export class SetProviders implements Action {
  readonly type = SET_PROVIDERS;

  constructor() { }
}

export class SetProvidersSuccess implements Action {
  readonly type = SET_PROVIDERS_SUCCESS;

  constructor(public response: Provider[]) { }
}

export class SetAccounts implements Action {
  readonly type = SET_ACCOUNTS;

  constructor(public params?: GetAccountsParams) { }
}

export class SetSubAccountsSuccess implements Action {
  readonly type = SET_SUB_ACCOUNTS_SUCCESS;

  constructor(public response: SubAccount[]) { }
}

export class SetSubAccounts implements Action {
  readonly type = SET_SUB_ACCOUNTS;

  constructor() { }
}

export class SetAccountsSuccess implements Action {
  readonly type = SET_ACCOUNTS_SUCCESS;

  constructor(public response: Account[]) { }
}

export class SetSenderNames implements Action {
  readonly type = SET_SENDER_NAMES;

  constructor(
    public params: Params = {},
    public isWithSmsServiceTrials = false,
    public isAccountWithRestrictedTrialSid = false,
  ) { }
}

export class SetSenderNamesSuccess implements Action {
  readonly type = SET_SENDER_NAMES_SUCCESS;

  constructor(public response: UniSenderName[]) { }
}

export class ClearSenderNames implements Action {
  readonly type = CLEAR_SENDER_NAMES;

  constructor() { }
}

export class SetAllAccounts implements Action {
  readonly type = SET_All_ACCOUNTS;

  constructor(public params: Params = {}) { }
}

export class SetAllAccountsSuccess implements Action {
  readonly type = SET_All_ACCOUNTS_SUCCESS;

  constructor(public response: Account[]) { }
}

export class SetAccountsChoice implements Action {
  readonly type = SET_ACCOUNTS_CHOICE;

  constructor() { }
}

export class SetAccountsChoiceSuccess implements Action {
  readonly type = SET_ACCOUNTS_CHOICE_SUCCESS;

  constructor(public response: Accounts) { }
}

export class PutAccountTroubleshootingMode implements Action {
  readonly type = PUT_ACCOUNT_TROUBLESHOOTING_MODE;

  constructor(
    public accountId: string,
    public isEnabled: boolean,
  ) { }
}

export class PutAccountTroubleshootingModeSuccess implements Action {
  readonly type = PUT_ACCOUNT_TROUBLESHOOTING_MODE_SUCCESS;
}

export class SetProvidersChoice implements Action {
  readonly type = SET_PROVIDERS_CHOICE;

  constructor() { }
}

export class SetProvidersChoiceSuccess implements Action {
  readonly type = SET_PROVIDERS_CHOICE_SUCCESS;

  constructor(public response: Providers) { }
}

export class SetOperatorsChoice implements Action {
  readonly type = SET_OPERATORS_CHOICE;

  constructor(public params?: OperatorsParams) { }
}

export class SetOperatorsChoiceSuccess implements Action {
  readonly type = SET_OPERATORS_CHOICE_SUCCESS;

  constructor(public response: Operators) { }
}

export class SetSendersChoice implements Action {
  readonly type = SET_SENDERS_CHOICE;

  constructor(public accountID: string) { }
}

export class SetSendersChoiceSuccess implements Action {
  readonly type = SET_SENDERS_CHOICE_SUCCESS;

  constructor(public response: Senders) { }
}

export class SetActivePackages implements Action {
  readonly type = SET_ACTIVE_PACKAGES;

  constructor() { }
}

export class SetActivePackagesSuccess implements Action {
  readonly type = SET_ACTIVE_PACKAGES_SUCCESS;

  constructor(public response: ActivePackages) { }
}

export type All =
  | SetOperators
  | SetOperatorsSuccess
  | SetProviders
  | SetProvidersSuccess
  | SetAccounts
  | SetAccountsSuccess
  | SetSubAccounts
  | SetSubAccountsSuccess
  | SetSenderNames
  | SetSenderNamesSuccess
  | ClearSenderNames
  | SetAllAccounts
  | SetAllAccountsSuccess
  | SetAccountsChoice
  | SetAccountsChoiceSuccess
  | PutAccountTroubleshootingMode
  | PutAccountTroubleshootingModeSuccess
  | SetOperatorsChoice
  | SetOperatorsChoiceSuccess
  | SetProvidersChoice
  | SetProvidersChoiceSuccess
  | SetSendersChoice
  | SetSendersChoiceSuccess
  | SetActivePackages
  | SetActivePackagesSuccess;
