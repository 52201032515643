import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateUtils, UniLanguageService, AutoUnsubscribe, UniAuthService, UniLayoutFacade } from '@unifonic/common';


@Component({
  selector: 'fs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DateUtils.datepickerFormat },
  ],
})
export class AppComponent extends AutoUnsubscribe implements OnInit {
  isLoaderActive$ = this.uniLayoutFacade.isLoaderActive$;
  constructor(
    private uniLanguageService: UniLanguageService,
    private uniLayoutFacade: UniLayoutFacade,
    private uniAuthService: UniAuthService
  ) {
    super();
    this.uniAuthService.clearUserData();
  }

  ngOnInit() {
    this.uniLanguageService.initLanguages();
  }
}

