import * as WebhooksActions from './webhooks.actions';
import {
  initialState,
  WebhooksState
} from './webhooks.state';


export function webhooksReducer(
  state = initialState,
  action: WebhooksActions.All,
): WebhooksState {
  switch (action.type) {
    case WebhooksActions.GET_WEBHOOKS_SUCCESS: {
      return {
        ...state,
        webhooks: action.response,
      };
    }
    case WebhooksActions.SET_WEBHOOKS_AMOUNT: {
      return {
        ...state,
        webhooksAmount: action.amount,
      };
    }
    case WebhooksActions.GET_WEBHOOK_SUCCESS: {
      return {
        ...state,
        webhook: action.webhook,
      };
    }
    case WebhooksActions.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: action.response,
      };
    }
    case WebhooksActions.GET_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.response,
      };
    }
    case WebhooksActions.GET_QUEUED_EVENTS_SUCCESS: {
      return {
        ...state,
        queuedEvents: action.response,
      };
    }
    case WebhooksActions.POST_WEBHOOK_SUCCESS: {
      return {
        ...state,
        webhook: action.response,
      };
    }
    default: {
      return {...state};
    }
  }
}
